import debug from 'debug';
import { allHoneyRoles } from '../lib/roles';

export default function ({ store, redirect, route }) {
  return new Promise((resolve) => {
    debug('bambee:nuxt')('auth middleware');

    if (route.path.includes('/login')) {
      return resolve();
    }
    if (!store.state.current_user) {
      return redirect(`/login?redirect_uri=${encodeURI(route.path)}`);
    }

    if (!hasAdminAccess(store.state.current_user)) {
      return redirect(`/login?redirect_uri=${encodeURI(route.path)}`);
    }
    if (!hasPageAccess(store.state.current_user, route.path)) {
      return redirect('/');
    }

    resolve();
  });
}

function hasAdminAccess(user) {
  const roles = allHoneyRoles;

  return roles.includes(user.role);
}

function hasPageAccess(user, page) {
  const roles = allHoneyRoles;
  const { role } = user;

  if (roles.includes(role)) {
    return true;
  }
  if (page === '/') {
    return true;
  }

  const access = {
    'hr-admin': ['customers', 'tickets'],
    obs: ['customers', 'tickets'],
    csr: ['customers', 'tickets'],
  };

  const user_access = access[role];

  const name = page.split('/')[1];

  const has_access = user_access.includes(name);

  return has_access;
}
