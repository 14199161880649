import CookieNames from '@/constants/CookieNames';
import { deleteCookie, setCookie } from '~/lib/cookie-helpers';

export const state = () => ({
  current_user: null,
  calendars: [],
  calendarEvents: [],
  crumbs: [],
  configurations: [],
});

export const mutations = {
  setCurrentUser(state, user) {
    state.current_user = user;
  },

  setCurrentUserProfile(state, profile) {
    state.current_user.profile = profile;
  },

  setGoogleCalendars(state, calendars) {
    state.calendars = calendars;
  },
  setCalendarEvents(state, events) {
    state.calendarEvents = events;
  },
  setCrumbs(state, crumbs) {
    state.crumbs = crumbs.filter((c) => c);
  },
  setConfigurations(state, configurations) {
    state.configurations = configurations;
  },
};

export const getters = {
  currentUser(state) {
    return state.current_user;
  },
  myCalendars(state) {
    return state.calendars;
  },
  calendarEvents(state) {
    return state.calendarEvents;
  },
  companyId(state, getters, rootState, rootGetters) {
    // proxy to easily get the current company's ID in scope
    // using `rootGetters: {companyId}` in any store module
    const company = rootGetters['companies/inScope'];

    return company && company._id;
  },
};

export const actions = {
  async login({ commit, dispatch }, { email, password }) {
    deleteCookie(CookieNames.ACCESS_TOKEN);
    deleteCookie(CookieNames.REFRESH_TOKEN);
    this.$axios.setToken(false);

    const loginResponse = await this.$axios.post(
      '/auth/v3/login',
      {
        email,
        password,
      },
      {
        baseURL: `${process.env.PUBLICAPI_URL}`,
        withCredentials: true,
      }
    );

    const { accessToken, refreshToken } = loginResponse.data;
    setCookie(CookieNames.ACCESS_TOKEN, accessToken);
    setCookie(CookieNames.REFRESH_TOKEN, refreshToken);
    this.$axios.setToken(accessToken, 'Bearer');
    const userResponse = await this.$axios.get('/v0/my/v1/self', {
      baseURL: `${process.env.PUBLICAPI_URL}`,
      withCredentials: true,
    });

    commit('setCurrentUser', userResponse.data);
    dispatch('advisor/get', null, { root: true });

    return userResponse.data;
  },

  logout({ commit }) {
    commit('setCurrentUser', null);
    deleteCookie(CookieNames.ACCESS_TOKEN);
    deleteCookie(CookieNames.REFRESH_TOKEN);

    this.$axios.setToken(false);
    this.$router.push('/login');
  },

  removeOauthIdentity({ commit }, iden_id) {
    return this.$axios
      .post('/self/remove-oauth-token', { iden_id })
      .then((res) => {
        const user = res.data;

        commit('setCurrentUser', user);

        return user;
      });
  },

  getGoogleCalendars({ commit }) {
    return this.$axios.get('/self/google-calendars').then((res) => {
      const calendars = res.data;

      commit('setGoogleCalendars', calendars);

      return calendars;
    });
  },

  setSchedulingCalendar({ commit }, { calendar_id }) {
    return this.$axios
      .post('/self/set-scheduling-calendar', { calendar_id })
      .then((res) => {
        const user = res.data;

        commit('setCurrentUser', user);

        return user;
      });
  },

  saveIntercomUserId({ commit }, { intercomUserId, intercomEmail }) {
    return this.$axios
      .post('/self/save-intercom-user-id', {
        intercomUserId,
        intercomEmail,
      })
      .then((res) => {
        const user = res.data;
        commit('setCurrentUser', user);

        return user;
      });
  },

  saveSetting({ commit }, { settingType, id }) {
    const payload = {
      [settingType]: id,
    };

    return this.$axios
      .post('/self/save-setting', payload)
      .then((res) => commit('setCurrentUser', res.data));
  },
};
