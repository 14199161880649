// ActionTiles constants
export const TileVariants = Object.freeze({
  DEFAULT: 'default',
  OFF_CYCLE: 'offCycle',
  PRIORITY: 'priority',
});

export const PayrollActions = Object.freeze({
  REGULAR: 'REGULAR',
  SCHEDULED_PAYROLL: 'scheduledPayroll',
  OFF_CYCLE: 'OFF_CYCLE',
});

// COMMON Payroll Constants
export const PayrollStatus = Object.freeze({
  INITIAL: 'initial',
  DRAFT: 'draft',
  PROCESSING: 'processing',
  PENDING: 'pending',
  PAID: 'paid',
});

export const PayrollActionState = Object.freeze({
  ...PayrollStatus,
  OVERDUE: 'overdue',
  MANUAL_CHECKS: 'manual_checks',
});

export const PayrollTerms = Object.freeze({
  DEADLINE: 'Deadline',
  PAY_DAY: 'Pay Day',
  TOTAL_PAYROLL: 'Total Payroll',
  GROSS_TOTAL: 'Gross Total',
  STAFF_COUNT: 'Staff Count',
});

export const PayrollType = Object.freeze({
  REGULAR: 'Regular',
  OFF_CYCLE: 'Off-Cycle',
});

export const PayerType = Object.freeze({
  COMPANY: 'COMPANY',
  EMPLOYEE: 'EMPLOYEE',
});

export const EmployeeTypes = Object.freeze({
  HOURLY: 'HOURLY',
  SALARY: 'SALARY',
});

export const EarningTypeBKP = Object.freeze({
  BONUS: 'Bonus',
  CASH_TIPS: 'Cash Tips',
  COMMISSION: 'Commision',
  DOUBLE_OVERTIME: 'Double Overtime',
  GROUP_TERM_LIFE: 'Group Term Life',
  HOURLY: 'Regular Hours',
  OTHER_IMPUTED: 'Other Imputed',
  OVERTIME: 'Overtime',
  PAID_HOLIDAY: 'Paid Holiday',
  PAYCHECK_TIPS: 'Paycheck Tips',
  PTO: 'PTO',
  SALARIED: 'Salary',
  SEVERANCE: 'Severance',
  SICK: 'Sick',
});

export const PayType = Object.freeze({
  HOURLY: {
    label: 'Hourly',
    value: 'HOURLY',
  },
  SALARY: {
    label: 'Salary',
    value: 'SALARY',
  },
});

export const EarningType = Object.freeze({
  BONUS: {
    label: 'Bonus',
    value: 'BONUS',
  },
  CASH_TIPS: {
    label: 'Cash Tips',
    value: 'CASH_TIPS',
  },
  COMMISSION: {
    label: 'Commission',
    value: 'COMMISSION',
  },
  DOUBLE_OVERTIME: {
    label: 'Double Overtime',
    value: 'DOUBLE_OVERTIME',
  },
  GROUP_TERM_LIFE: {
    label: 'Group Term Life',
    value: 'GROUP_TERM_LIFE',
  },
  HOURLY: {
    label: 'Regular Hours',
    value: 'HOURLY',
  },
  OTHER_IMPUTED: {
    label: 'Other Imputed',
    value: 'OTHER_IMPUTED',
  },
  OVERTIME: {
    label: 'Overtime',
    value: 'OVERTIME',
  },
  PAID_HOLIDAY: {
    label: 'Paid Holiday',
    value: 'PAID_HOLIDAY',
  },
  PAYCHECK_TIPS: {
    label: 'Paycheck Tips',
    value: 'PAYCHECK_TIPS',
  },
  PTO: {
    label: 'PTO',
    value: 'PTO',
  },
  SALARIED: {
    label: 'Salary  ',
    value: 'SALARIED',
  },
  SEVERANCE: {
    label: 'Severance',
    value: 'SEVERANCE',
  },
  SICK: {
    label: 'Sick',
    value: 'SICK',
  },
});

export const DateFormat = Object.freeze({
  MM_DD_YYYY: 'MM/dd/yyyy',
  WD_MM_DD_YYYY: 'E MM/dd/yyyy',
  YYYY_MM_DD: 'yyyy-MM-dd',
});

export const PayFrequency = Object.freeze({
  WEEKLY: 'WEEKLY',
  BIWEEKLY: 'BIWEEKLY',
  SEMIMONTHLY: 'SEMIMONTHLY',
  MONTHLY: 'MONTHLY',
});

export const CompanyStatus = Object.freeze({
  INITIALIZED: 'INITIALIZED',
  LIVE: 'LIVE',
  PROVISIONED: 'PROVISIONED',
  SETUP_COMPLETE: 'SETUP_COMPLETE',
  BACKED_OUT: 'BACKED_OUT',
  CANCELLED: 'CANCELLED',
});

export const Timer = Object.freeze({
  SHORT: 1000,
  MEDIUM: 3000,
  LONG: 5000,
  XLONG: 10000,
});

// EearningsForm.vue Constants

// All options until we have correct values coming from BE
export const AllOptions = [
  EarningType.SALARIED,
  EarningType.HOURLY,
  EarningType.OVERTIME,
  EarningType.DOUBLE_OVERTIME,
  EarningType.PTO,
  EarningType.SICK,
  EarningType.CASH_TIPS,
  EarningType.PAID_HOLIDAY,
  EarningType.PAYCHECK_TIPS,
  EarningType.BONUS,
  EarningType.COMMISSION,
  EarningType.SEVERANCE,
];

// The following types should only be saved with hourly employees
export const HourlyOptions = [
  EarningType.HOURLY,
  EarningType.OVERTIME,
  EarningType.DOUBLE_OVERTIME,
  EarningType.PTO,
  EarningType.SICK,
  EarningType.BONUS,
  EarningType.COMMISSION,
  EarningType.SEVERANCE,
  EarningType.CASH_TIPS,
  EarningType.PAID_HOLIDAY,
  EarningType.PAYCHECK_TIPS,
];
// The following types should only be saved with salaried employees
export const SalariedOptions = [
  EarningType.SALARIED,
  EarningType.OVERTIME,
  EarningType.DOUBLE_OVERTIME,
  EarningType.PTO,
  EarningType.SICK,
  EarningType.BONUS,
  EarningType.COMMISSION,
  EarningType.SEVERANCE,
  EarningType.CASH_TIPS,
  EarningType.PAID_HOLIDAY,
  EarningType.PAYCHECK_TIPS,
];
// The following earning types require an hours amount
export const HourlyTypes = [
  EarningType.DOUBLE_OVERTIME,
  EarningType.HOURLY,
  EarningType.OVERTIME,
  EarningType.PAID_HOLIDAY,
  EarningType.PTO,
  EarningType.SALARIED,
  EarningType.SICK,
];
// The following earning types do not accept an hours amount.
export const NonHourlyTypes = [
  EarningType.BONUS,
  EarningType.CASH_TIPS,
  EarningType.COMMISSION,
  EarningType.GROUP_TERM_LIFE,
  EarningType.OTHER_IMPUTED,
  EarningType.PAYCHECK_TIPS,
  EarningType.SEVERANCE,
];

export const PayTypes = Object.freeze({
  HOURLY: 'HOURLY',
  SALARY: 'SALARY',
  CONTRACT: 'CONTRACT',
  CONTRACTOR: 'CONTRACTOR',
});

export const DefaultEarningValues = Object.freeze({
  amount: 0,
  description: null,
  minutes: 0,
  type: 'DEFAULT',
});

export const PaymentMethod = Object.freeze({
  MANUAL: Object.freeze({
    label: 'Manual Check',
    value: 'MANUAL',
  }),
  DIRECT_DEPOSIT: Object.freeze({
    label: 'Direct Deposit',
    value: 'DIRECT_DEPOSIT',
  }),
});

export const PaymentMethodOptions = [
  PaymentMethod.MANUAL,
  PaymentMethod.DIRECT_DEPOSIT,
];

export default {
  TileVariants,
  PayrollActions,
  PayrollStatus,
  EarningType,
  HourlyOptions,
  SalariedOptions,
  HourlyTypes,
  NonHourlyTypes,
  DefaultEarningValues,
  PaymentMethod,
};
