import qs from 'qs';

export default async ({ route, store, query, redirect }) => {
  const { path } = route;

  const isCompanyPath = path.split('/')[1] === 'company';

  if (!isCompanyPath) {
    return false;
  }

  const newPath = path.split('/');

  const company = await store.dispatch(
    'companies/getCompanyBasicInfo',
    newPath[2]
  );

  newPath[1] = 'customers';
  newPath[2] = company.ownerId;

  redirect(`${newPath.join('/')}?${qs.stringify(query)}`);

  return true;
};
