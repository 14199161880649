export const state = () => ({
  notice: null,
});

export const mutations = {
  setNewHireNotice(state, notice) {
    state.notice = notice;
  },
};

export const actions = {
  get({ commit }) {
    return this.$axios.get('/new-hire-notice/').then((res) => {
      commit('setNewHireNotice', res.data);
    });
  },

  create({ commit }, { data }) {
    return this.$axios.post(`/new-hire-notice/create`, { data }).then((res) => {
      commit('setNewHireNotice', res.data);
    });
  },

  update({ commit }, { data }) {
    return this.$axios.post(`/new-hire-notice/update`, data).then((res) => {
      commit('setNewHireNotice', res.data);
    });
  },
};
