<template>
  <div class="bam-loader-icon">
    <div>
      <div class="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @usage
 * Use classes loader-size-** for setting size
 *
 * TODO: switch to vuetify-loader with vuetify v2.1
 */
export default {
  name: 'LoaderIcon',
};
</script>

<style lang="scss">
.bam-loader-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  $sizes: (
    'sm': 240px,
    'md': 360px,
    'lg': 480px,
  );

  @each $name, $size in $sizes {
    &.loader-size-#{$name} {
      min-height: $size;
    }
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #69488e;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #69488e transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
