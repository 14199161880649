<template>
  <v-app class="empty-layout">
    <nuxt />
  </v-app>
</template>

<script>
export default {
  name: 'EmptyLayout',
};
</script>
