import Vue from 'vue';
import difference from 'lodash/difference';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import keyBy from 'lodash/keyBy';
import dayjs from 'dayjs';

import {
  insuranceLines,
  featuredInsuranceLineIds,
} from '@/lib/globals/insurance';

const createPlanPayload = function (plan, agent, files) {
  const limits = plan.limits.map((limit) => {
    return {
      key: limit.key,
      value: limit.value.toString(),
    };
  });

  const payload = {
    ...plan,
    limits,
    agent,
    files,
    carrierId: plan.carrier.id || undefined,
    effectiveDate: dayjs(plan.effectiveDate).toISOString(),
    expirationDate: dayjs(plan.expirationDate).toISOString(),
    premium: +plan.premium || undefined,
    deductible: +plan.deductible || undefined,
  };

  if (!payload.carrierId) {
    payload.unassociatedCarrierName = payload.carrier.name;
  }
  delete payload.carrier;

  return payload;
};

const formatPlan = function (rawPlan) {
  const plan = cloneDeep(rawPlan);
  const line = insuranceLines[plan.lineType];

  plan._id = plan.id; // vuetify table requirement
  plan.order = (line && line.featuredOrder) || 1000;
  plan.lineTypeName = plan.lineTypeName || (line && line.name);
  plan.lineTypeShortName = plan.lineTypeShortName || (line && line.shortName);
  plan.effectiveDate = dayjs(plan.effectiveDate).format('YYYY-MM-DD');
  plan.expirationDate = dayjs(plan.expirationDate).format('YYYY-MM-DD');
  const coverageIndex = plan.limits.findIndex((l) => l.key === 'coverage');
  if (coverageIndex < 0) {
    plan.limits.push({
      key: 'coverage',
      value: '',
    });
  }

  return plan;
};

export const state = () => ({
  carriers: {},
  insuranceLines: {},
  plans: {},
  agentByPlan: {},
  filesByPlan: {},
  existingPlansByCompany: {},
  archivedPlansByCompany: {},
});

export const getters = {
  // Generic getters
  carriers: (state) => Object.values(state.carriers),
  carrierById: (state) => (carrierId) => state.carriers[carrierId],
  lines: (state) => state.insuranceLines,
  planById: (state) => (planId) => state.plans[planId],
  agentByPlanId: (state) => (planId) => state.agentByPlan[planId],
  filesByPlanId: (state) => (planId) => state.filesByPlan[planId],
  existingPlansByCompany: (state) => (companyId) =>
    state.existingPlansByCompany[companyId],
  archivedPlansByCompany: (state) => (companyId) =>
    state.archivedPlansByCompany[companyId],

  // Getting for company in scope
  activePlansInScope: (state, getters, rootState, rootGetters) => {
    const existingLines = [];
    const existingPlans = (
      getters.existingPlansByCompany(rootGetters.companyId) || []
    ).map((id) => {
      existingLines.push(state.plans[id].lineType);

      return state.plans[id];
    });

    const availableLineIds = difference(
      featuredInsuranceLineIds,
      existingLines
    );
    const availablePlans = availableLineIds.map((lineId) => {
      const line = insuranceLines[lineId];

      return {
        lineType: line.id,
        lineTypeName: line.name,
        lineTypeShortName: line.shortName,
        order: line.featuredOrder,
      };
    });
    const plans = [...availablePlans, ...existingPlans];

    /**
     * Sorting order
     *
     * Active BOR-in-progress
     * Active Bambee or BOR (managed by Bambee)
     * Active manually entered plans
     * Draft plans
     * Available (placeholder) plans
     */
    const sortingByImportance = function (plan) {
      // Available (placeholder) plans
      if (!plan._id) {
        return 100;
      }

      // Active BOR-in-progress
      if (
        plan.status === 'unknown' &&
        plan.bambeePlanStatus === 'import-in-progress'
      ) {
        return 10;
      }

      // Active Bambee or BOR (managed by Bambee)
      if (
        ['is-sold-by-bambee', 'is-broker-of-record'].includes(
          plan.bambeePlanStatus
        )
      ) {
        return 20;
      }

      // Drafts
      if (plan.status === 'draft') {
        return 70;
      }

      return 30;
    };

    return sortBy(plans, [sortingByImportance, 'order', 'expirationDate']);
  },
  archivedPlansInScope(state, getters, rootState, rootGetters) {
    const plans = (
      getters.archivedPlansByCompany(rootGetters.companyId) || []
    ).map((id) => state.plans[id]);

    return sortBy(plans, ['order', 'expirationDate']);
  },
};

export const actions = {
  // Insurance Lines
  fetchInsuranceLines({ state, dispatch }) {
    // avoid getting the list from backend every time
    if (Object.keys(state.insuranceLines).length) {
      return Promise.resolve(state.insuranceLines);
    }

    return dispatch('insurance/getInsuranceLines');
  },
  getInsuranceLines({ commit, state }) {
    return this.$axios
      .get(`/insurance/v1/lines`, { baseURL: process.env.API_URL })
      .then((res) => {
        commit('setInsuranceLines', res.data);

        return state.insuranceLines;
      });
  },

  // Carriers
  getCarrierById({ commit }, carrierId) {
    return this.$axios
      .get(`/insurance/v1/carriers/carrier/${carrierId}`, {
        baseURL: process.env.API_URL,
      })
      .then((res) => {
        commit('setCarriers', [res.data]);
      });
  },
  searchCarriersByName({ commit }, search) {
    return this.$axios
      .post(
        `/insurance/v1/carriers/search`,
        { searchTerms: search },
        { baseURL: process.env.API_URL }
      )
      .then((res) => {
        commit('setCarriers', res.data);

        return res.data;
      });
  },

  // Plans Overview
  getActivePlans({ commit, dispatch, rootGetters: { companyId } }) {
    return dispatch('getPlans', {
      status: [
        'current',
        'reinstated',
        'pending-cancellation',
        'draft',
        'unknown',
      ],
      effectiveAt: null,
    }).then((plans) => {
      commit('setPlans', { plans,
companyId,
type: 'active' });
    });
  },
  getArchivedPlans({ commit, dispatch, rootGetters: { companyId } }) {
    return dispatch('getPlans', {
      status: ['archived'],
      effectiveAt: null,
    }).then((plans) => {
      commit('setPlans', { plans,
companyId,
type: 'archived' });
    });
  },
  getPlans({ rootGetters: { companyId } }, { status = [], effectiveAt = '' }) {
    let statusLine = '';
    if (status.length) {
      status.forEach((s) => {
        statusLine += `status=${s}&`;
      });
    }
    const effectiveAtLine =
      effectiveAt === '' ? '' : `effectiveAt=${effectiveAt}`;

    return this.$axios
      .get(
        `/companies/v1/company/${companyId}/insurance/plans?${statusLine}${effectiveAtLine}`,
        { baseURL: process.env.API_URL }
      )
      .then((res) => {
        return res.data;
      });
  },

  // Plan CRUD
  getPlan({ commit, rootGetters: { companyId } }, planId) {
    return this.$axios
      .get(
        `/companies/v1/company/${companyId}/insurance/plans/plan/${planId}`,
        { baseURL: process.env.API_URL }
      )
      .then((res) => {
        commit('setPlan', { companyId, plan: res.data });
      });
  },
  createPlan({ commit, rootGetters: { companyId } }, { plan, agent, files }) {
    const payload = createPlanPayload(plan, agent, files);

    return this.$axios
      .post(
        `/companies/v1/company/${companyId}/insurance/plans/plan`,
        payload,
        { baseURL: process.env.API_URL }
      )
      .then((res) => {
        commit('setPlan', {
          companyId,
          plan: res.data,
          add: true,
        });
        commit('setPlanFilesInBulk', {
          planId: res.data.id,
          files,
        });
      });
  },
  updatePlan({ commit, dispatch, rootGetters: { companyId } }, { plan }) {
    const payload = createPlanPayload(plan);

    return this.$axios
      .patch(
        `/companies/v1/company/${companyId}/insurance/plans/plan/${plan.id}`,
        payload,
        { baseURL: process.env.API_URL }
      )
      .then(() => {
        commit('setPlan', { companyId, plan });
      });
  },
  deletePlan({ commit, rootGetters: { companyId } }, planId) {
    return this.$axios
      .delete(
        `/companies/v1/company/${companyId}/insurance/plans/plan/${planId}`,
        { baseURL: process.env.API_URL }
      )
      .then(() => {
        commit('deletePlan', { companyId, planId });
      });
  },

  // More Plan Actions
  archivePlan({ commit, rootGetters: { companyId } }, { planId, setArchived }) {
    return this.$axios
      .patch(
        `/companies/v1/company/${companyId}/insurance/plans/plan/${planId}/archive`,
        { value: setArchived },
        { baseURL: process.env.API_URL }
      )
      .then(() => {
        commit('updatePlanArchivedStatus', { companyId, planId, setArchived });
      });
  },

  // Agent Extension
  getPlanAgent({ commit, rootGetters: { companyId } }, planId) {
    return this.$axios
      .get(
        `/companies/v1/company/${companyId}/insurance/plans/plan/${planId}/agent`,
        { baseURL: process.env.API_URL }
      )
      .then((res) => {
        commit('setPlanAgent', {
          planId,
          agent: res.data,
        });
      });
  },
  updatePlanAgent({ commit, rootGetters: { companyId } }, { planId, agent }) {
    return this.$axios
      .put(
        `/companies/v1/company/${companyId}/insurance/plans/plan/${planId}/agent`,
        agent,
        { baseURL: process.env.API_URL }
      )
      .then(() => {
        commit('setPlanAgent', {
          planId,
          agent,
        });
      });
  },

  // Files Extension
  getPlanFiles({ commit, rootGetters: { companyId } }, planId) {
    return this.$axios
      .get(
        `/companies/v1/company/${companyId}/insurance/plans/plan/${planId}/files`,
        { baseURL: process.env.API_URL }
      )
      .then((res) => {
        commit('setPlanFilesInBulk', {
          planId,
          files: res.data,
        });
      });
  },
  addPlanFile({ commit, rootGetters: { companyId } }, { planId, file }) {
    return this.$axios
      .post(
        `/companies/v1/company/${companyId}/insurance/plans/plan/${planId}/files`,
        file,
        { baseURL: process.env.API_URL }
      )
      .then((res) => {
        file.id = res.data.fileId;
        commit('setPlanFile', {
          planId,
          file,
        });
      });
  },
  deletePlanFile({ commit, rootGetters: { companyId } }, { planId, fileId }) {
    return this.$axios
      .delete(
        `/companies/v1/company/${companyId}/insurance/plans/plan/${planId}/files/file/${fileId}`,
        { baseURL: process.env.API_URL }
      )
      .then(() => {
        commit('deletePlanFile', {
          planId,
          fileId,
        });
      });
  },

  // BOR
  downloadBorLetter({ rootGetters: { companyId } }, planId) {
    return this.$axios
      .get(
        `/companies/v1/company/${companyId}/insurance/plans/plan/${planId}/bor-letter`,
        {
          baseURL: process.env.API_URL,
          responseType: 'blob',
        }
      )
      .then((res) => {
        return res.data;
      });
  },
};

export const mutations = {
  // Insurance Lines
  setInsuranceLines(state, lineIds) {
    lineIds.forEach((lineId) => {
      const line = insuranceLines[lineId];
      if (line) {
        Vue.set(state.insuranceLines, line.id, line);
      }
    });
  },

  // Carriers
  setCarriers(state, carriers) {
    carriers.forEach((c) => {
      Vue.set(state.carriers, c.id, c);
    });
  },

  // Plans
  setPlans(state, { companyId, plans, type }) {
    if (!type) {
      return false;
    }

    const planIdsCollection = plans.map((plan) => {
      Vue.set(state.plans, plan.id, formatPlan(plan));

      return plan.id;
    });

    const collection =
      type === 'active' ? 'existingPlansByCompany' : 'archivedPlansByCompany';
    Vue.set(state[collection], companyId, planIdsCollection);
  },

  // Plan CRUD
  setPlan(state, { companyId, plan, add = false }) {
    Vue.set(state.plans, plan.id, formatPlan(plan));

    if (add) {
      state.existingPlansByCompany[companyId].push(plan.id);
    }
  },
  deletePlan(state, { companyId, planId }) {
    const index = state.existingPlansByCompany[companyId].indexOf(planId);
    if (index > -1) {
      state.existingPlansByCompany[companyId].splice(index, 1);
    }

    Vue.delete(state.plans, planId);
  },

  // More Plan Actions
  updatePlanArchivedStatus(state, { companyId, planId, setArchived }) {
    const pool = setArchived
      ? 'existingPlansByCompany'
      : 'archivedPlansByCompany';
    const newPool = setArchived
      ? 'archivedPlansByCompany'
      : 'existingPlansByCompany';

    const index = state[pool][companyId].indexOf(planId);
    if (index > -1) {
      state[pool][companyId].splice(index, 1);
    }

    state[newPool][companyId].push(planId);
  },

  // Agent Extension
  setPlanAgent(state, { planId, agent }) {
    Vue.set(state.agentByPlan, planId, agent);
  },

  // Files Extension
  setPlanFilesInBulk(state, { planId, files }) {
    Vue.set(state.filesByPlan, planId, files);
  },
  setPlanFile(state, { planId, file }) {
    state.filesByPlan[planId] = state.filesByPlan[planId] || [];
    state.filesByPlan[planId].push(file);
  },
  deletePlanFile(state, { planId, fileId }) {
    const index = state.filesByPlan[planId].findIndex((f) => f.id === fileId);
    if (index > -1) {
      Vue.delete(state.filesByPlan[planId], index);
    }
  },
};
