<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M22.8464 23.1281L23.9714 18.2531C24.0932 17.7234 23.8167 17.1797 23.3198 16.9641L18.0698 14.7141C17.6104 14.5172 17.0714 14.6484 16.7573 15.0375L14.4323 17.8781C10.837 16.1906 7.84637 13.2422 6.12606 9.57187L8.96668 7.24688C9.35574 6.92813 9.48699 6.39375 9.29012 5.93437L7.04012 0.684375C6.81981 0.182812 6.27606 -0.09375 5.74637 0.028125L0.871368 1.15313C0.365118 1.27031 -0.000507355 1.72031 -0.000507355 2.25C-0.000507355 14.2547 9.72606 24 21.7495 24C22.2745 24 22.7292 23.6391 22.8464 23.1281Z"
        fill="#5EDEC8"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'PhoneIcon',
};
</script>
