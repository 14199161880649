import Vue from 'vue';
import currency from 'v-currency-field';

import 'v-currency-field/dist/index.css';

Vue.use(currency, {
  locale: 'en-US',
  prefix: '$',
  masked: false,
});
