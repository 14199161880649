export const Roles = {
  DEFAULT: 'default',
  ADMIN: 'admin',
  CSR: 'csr',
  EMPLOYEE: 'employee',
  HR_ADMIN: 'hr-admin',
  INSURANCE: 'insurance',
  PARTNER: 'partner',
  SALES: 'sales',
  SDR: 'sdr',
  USER: 'user',
  OBS: 'obs',
  HRM: 'hrm',
  HBS: 'hbs',
  PYM: 'pym',
};

export const hrRoles = [
  Roles.DEFAULT,
  Roles.HR_ADMIN,
  Roles.OBS,
  Roles.CSR,
  Roles.HRM,
  Roles.HBS,
];

export const hrAndGod = [Roles.ADMIN, ...hrRoles];
export const hrAndSales = [Roles.SALES, Roles.SDR, Roles.INSURANCE, ...hrRoles];

export const salesPartnerAndGod = [
  Roles.ADMIN,
  Roles.SALES,
  Roles.SDR,
  Roles.INSURANCE,
  Roles.PARTNER,
];

export const salesRoles = [Roles.SALES, Roles.SDR, Roles.INSURANCE];

export const allHoneyRoles = [
  Roles.ADMIN,
  Roles.PARTNER,
  ...hrRoles,
  ...salesRoles,
];

export const allRoles = [
  Roles.ADMIN,
  Roles.USER,
  Roles.HR_ADMIN,
  Roles.EMPLOYEE,
  Roles.OBS,
  Roles.CSR,
  Roles.SALES,
  Roles.SDR,
  Roles.INSURANCE,
  Roles.PARTNER,
  Roles.DEFAULT,
];

export const RoleNames = [
  // {
  //   label: 'Bambee Super Admin',
  //   value: 'admin'
  // },
  {
    label: 'Bambee HR-Admin',
    text: Roles.HR_ADMIN,
  },
  {
    label: 'Bambee Sales Admin',
    text: Roles.SALES,
  },
  {
    label: 'Bambee Partner',
    text: Roles.PARTNER,
  },
  {
    label: 'Bambee SDR',
    text: Roles.SDR,
  },
  {
    label: 'Bambee OBS',
    text: Roles.OBS,
  },
  {
    label: 'Bambee CSR',
    text: Roles.CSR,
  },
  {
    label: 'Bambee Insurance',
    text: Roles.INSURANCE,
  },
  {
    label: 'Bambee HR Manager',
    text: Roles.HRM,
  },
  {
    label: 'Bambee HB Specialist',
    text: Roles.HBS,
  },
  {
    label: 'Bambee Default',
    text: Roles.DEFAULT,
  },
];
