import Cookie from 'js-cookie';

const isSecure = process.env.env === 'production';

export function getCookie(name) {
  return Cookie.get(name);
}

export function setCookie(name, value) {
  const config = isSecure
    ? {
        samesite: 'none',
        secure: true,
      }
    : null;

  Cookie.set(name, value, config);
}

export function deleteCookie(name) {
  Cookie.remove(name);
}
