import Vue from 'vue';
import { mask } from 'vue-the-mask';
import Cleave from 'cleave.js';

require('cleave.js/dist/addons/cleave-phone.us');

export default () => {
  Vue.directive('focus', {
    inserted(el) {
      if (el) {
        el.focus();
      }
    },
  });

  Vue.directive('cleave', (el, binding) => {
    const input = el.querySelector('input');
    new Cleave(input, binding ? binding.value : null);
  });

  Vue.directive('mask', mask);
};
