import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Vue from 'vue';
import Vuetify from 'vuetify';
import VuetifyConfirm from 'vuetify-confirm';
import VuetifyToast from 'vuetify-toast';
import '../assets/stylus/index.styl';
import MaskIcon from '../components/custom-icons/mask-icon.vue';
import PhoneIcon from '../components/custom-icons/phone-icon.vue';

const installToast = {
  install() {
    Vue.prototype.$toast = VuetifyToast;
  },
};

export default () => {
  Vue.use(Vuetify, {
    iconfont: 'mdi',
    icons: {
      phone: {
        component: PhoneIcon,
      },
      mask: {
        component: MaskIcon,
      },
    },

    theme: {
      primary: '#481D72',
      secondary: '#1991A9',
      accent: '#3F3E40',
      error: '#EB5E5E',
      info: '#2894c5',
      success: '#28C5AA',
      warning: '#FFD558',
      google: '#5a86e4',
      grey: '#828282',
      'light-grey': '#E0E0E0',
      intercom: '#286efa',
    },
    options: {
      customProperties: true,
    },
  });

  Vue.use(installToast);
  Vue.use(VuetifyConfirm, {
    buttonTrueText: 'OK',
    buttonFalseText: 'No',
    color: 'warning',
    icon: 'warning',
    title: 'Warning',
    width: 300,
    property: '$confirm',
  });
};
