<template>
  <div
    ref="bamsvgicon"
    :style="style"
    class="o-svg-icon"
    v-html="
      require(`!!html-loader!./../../../assets/inlineSvgs/${this.$slots.default[0].text.trim()}.svg`)
    "
  />
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    width: {
      type: [String, Number],
      validator: (val) => val % 4 === 0,
    },
    height: {
      type: [String, Number],
      default: 24,
      validator: (val) => val % 4 === 0,
    },
    fill: {
      type: String,
      default: '#68478d',
    },
    className: {
      type: String,
    },
  },
  computed: {
    style() {
      return `width:${this.width || this.height}px;height:${this.height}px;`;
    },
  },
  mounted() {
    const elem = this.$refs.bamsvgicon;

    if (!elem) {
      return;
    }

    const svg = elem.querySelector('svg');

    if (svg !== null) {
      svg.removeAttribute('height');
      svg.removeAttribute('width');

      if (this.className) {
        svg.setAttribute('class', this.className);
      }

      const path = svg.querySelector('path');
      if (path !== null) {
        path.setAttribute('fill', this.fill);
      }
    }
  },
};
</script>

<style lang="scss">
.o-svg-icon {
  flex-shrink: 0;

  svg {
    height: 100%;
    width: 100%;
    vertical-align: top;
  }
}
</style>
