import Vue from 'vue';

export const state = () => ({
  leads: [],
});

export const mutations = {
  setLeads(state, slots) {
    state.leads = slots;
  },

  setLead(state, lead) {
    const index = state.leads.findIndex((l) => l.companyId == lead.companyId);

    Vue.set(state.leads, index, lead);
  },
};

export const getters = {
  all(state) {
    return state.leads;
  },
};

export const actions = {
  getLeads({ commit }, { query }) {
    const { ae, today, unqualified } = query;

    return this.$axios
      .get(`/lead?ae=${ae}&today=${today}&unqualified=${unqualified}`)
      .then((res) => {
        commit('setLeads', res.data);
      });
  },

  recordUnscheduledCall({ commit }, { lead, answered, ovComplete }) {
    return this.$axios
      .post(`/lead/record-unscheduled-call/${lead.companyId}/`, {
        answered,
        ovComplete,
      })
      .then(({ data }) => {
        commit('setLead', data);

        return data;
      });
  },

  recordCall({ commit }, { lead, call, answered, ovComplete }) {
    return this.$axios
      .post(`/lead/record-call/${lead.companyId}/${call._id}`, {
        answered,
        ovComplete,
      })
      .then(({ data }) => {
        commit('setLead', data);

        return data;
      });
  },

  recordQualification({ commit }, { lead, qualified, reason }) {
    return this.$axios
      .post(`/lead/update-qualifications/${lead.companyId}`, {
        qualified,
        reason,
      })
      .then(({ data }) => {
        commit('setLead', data);

        return data;
      })
      .catch(() => {
        this.$toast.error('Error');
      });
  },
};
