export const state = () => ({
  loaded: false,
  loading: false,
  pricePlans: [],
});

export const getters = {
  all(state) {
    return state.pricePlans;
  },
  activePlans(state) {
    return state.pricePlans.filter((p) => p.status === 'active' && !p.pause);
  },
};

export const mutations = {
  setPricePlans(state, pricePlans) {
    state.pricePlans = pricePlans;
    state.loaded = true;
  },

  setLoading(state, bool) {
    state.loading = bool;
  },
};

export const actions = {
  async fetch(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    { commit, state }
  ) {
    const status = ['active', 'deprecated'];
    const internal = true;
    const partner = '__all__';

    const { loaded, loading } = state;
    if (loaded || loading) {
      return state.pricePlans;
    }

    commit('setLoading', true);

    try {
      const res = await this.$axios.get('/billing/billing/v2/price-plans', {
        params: {
          internal,
          partner,
          status: status.join(','),
        },
        baseURL: `${process.env.PUBLICAPI_URL}`,
      });

      commit('setPricePlans', res.data);

      return [...res.data];
    } finally {
      commit('setLoading', false);
    }
  },
};
