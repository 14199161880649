import CookieNames from '@/constants/CookieNames';
import { getCookie } from '@/lib/cookie-helpers';
import { getRefreshedToken, jwtExpired } from '@/plugins/axios';
import { TokenRefreshLink } from 'apollo-link-token-refresh';

export function isTokenValidOrUndefined() {
  const token = getCookie(CookieNames.ACCESS_TOKEN);

  if (!token) {
    return true;
  }

  return !jwtExpired(token, 3000);
}

export default () => {
  const link = new TokenRefreshLink({
    isTokenValidOrUndefined,
    accessTokenField: 'accessToken',
    fetchAccessToken: () => getRefreshedToken(window.$nuxt.$axios),
    handleResponse: (operation, accessTokenField) => (response) => {
      return {
        [accessTokenField]: response,
      };
    },
    handleError: (err) => {
      console.error(err);
      window.$nuxt.$store.dispatch('logout');
    },
  });

  return link;
};
