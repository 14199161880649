export const state = () => ({
  profile: {},
  companyAdvisor: [],
  availableSlots: [],
});

export const getters = {
  timeslots(state) {
    return state.availableSlots;
  },

  profile(state) {
    return state.profile;
  },
};

export const mutations = {
  set(state, { profile, availableSlots }) {
    state.profile = profile;
    state.availableSlots = availableSlots;
  },

  setProfile(state, profile) {
    state.profile = profile;
  },

  setAvailableTimeslots(state, timeslots) {
    state.available_timeslots = timeslots;
  },

  setAdvisorEmployee(state, companyAdvisor) {
    state.companyAdvisor = companyAdvisor;
  },
};

export const actions = {
  get({ commit }) {
    return this.$axios.get('/self/advisor').then((res) => {
      const { profile, availableSlots } = res.data;

      commit('set', { profile, availableSlots });

      return { profile, availableSlots };
    });
  },

  refreshSelf({ commit }) {
    return this.$axios.get('/my/self').then((res) => {
      const user = res.data;

      commit('setCurrentUser', user);

      return user;
    });
  },

  getAdvisors({ commit }) {
    return this.$axios.get('/report/getAdvisorEmployeeCount').then((res) => {
      commit('setAdvisorEmployee', res.data);

      return res.data;
    });
  },

  bulkReassignAdvisor({ commit }, { url }) {
    return this.$axios.post(
      `/companies/v1/bulk-reassign-advisor`,
      {
        url,
      },
      {
        baseURL: `${process.env.API_URL}`,
      }
    );
  },
};
