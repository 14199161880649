import Vue from 'vue';
import Vuelidate from 'vuelidate';
import * as validators from 'vuelidate/lib/validators';

const US_PHONE_PATTERN = /^[2-9]\d{9}$/;

const bambeePhoneFormat = (phone) => (phone ? phone.length === 17 : true);
const bambeePhoneStrippedFormat = (phone) =>
  !phone || US_PHONE_PATTERN.test(phone);

const install = {
  install(Vue) {
    validators.bambeePhoneFormat = bambeePhoneFormat;
    validators.bambeePhoneStrippedFormat = bambeePhoneStrippedFormat;
    Vue.prototype.$_validators = validators;
  },
};

export default () => {
  Vue.use(Vuelidate);
  Vue.use(install);
};
