export const state = () => ({
  leads: [],
});

export const mutations = {
  setLeads(state, slots) {
    state.leads = slots;
  },
};

export const getters = {
  allLeads(state) {
    return state.leads;
  },
};

export const actions = {
  getLeads({ commit }, payload) {
    const { qnaFilters } = payload || {};

    return this.$axios.post('/lead/insurance', { qnaFilters }).then((res) => {
      commit('setLeads', res.data);
    });
  },
  createCall({ commit }, { companyId, interestSource, payload }) {
    const url = interestSource
      ? `/lead/insurance/${companyId}/create-call?interestSource=${interestSource}`
      : `/lead/insurance/${companyId}/create-call`;

    return this.$axios.post(url, payload).then(() => {
      commit(
        'companies/setInsuranceInterest',
        { companyId,
status: 'yes' },
        { root: true }
      );
    });
  },
  updateCallDetails(ctx, { companyId, callId, payload }) {
    return this.$axios.post(
      `/lead/insurance/${companyId}/reschedule-call/${callId}`,
      payload
    );
  },
};
