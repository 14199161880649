import { Roles } from '../lib/roles';

const godOnlyRoutes = ['/users', '/add-user', '/configuration'];

export default ({ store, redirect, route }) => {
  if (!store.state.current_user) {
    return;
  }

  // If user's role isn't 'admin' and they try to access godOnlyRoutes, they will be redirected to home
  if (
    store.state.current_user.role !== Roles.ADMIN &&
    route.path.includes(godOnlyRoutes)
  ) {
    return redirect('/');
  }
};
