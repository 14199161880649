<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0251 5C-4.57242 5 -1.38379 19.4 6.08284 19.4C7.57909 19.4 8.98796 18.6241 9.88609 17.3053L10.851 15.8881C11.4382 15.026 12.6123 15.026 13.1996 15.8881L14.1645 17.3053C15.0622 18.6241 16.4711 19.4 17.9673 19.4C25.0871 19.4 28.866 5 12.0251 5ZM6.89996 14.1635C5.36021 14.1635 4.35896 13.2013 3.89696 12.6241C3.70084 12.3793 3.70084 12.0208 3.89696 11.7755C4.35896 11.198 5.35984 10.2361 6.89996 10.2361C8.44009 10.2361 9.44096 11.1984 9.90296 11.7755C10.0991 12.0204 10.0991 12.3789 9.90296 12.6241C9.44096 13.2016 8.43971 14.1635 6.89996 14.1635ZM17.1 14.1635C15.5602 14.1635 14.559 13.2013 14.097 12.6241C13.9008 12.3793 13.9008 12.0208 14.097 11.7755C14.559 11.198 15.5598 10.2361 17.1 10.2361C18.6401 10.2361 19.641 11.1984 20.103 11.7755C20.2991 12.0204 20.2991 12.3789 20.103 12.6241C19.641 13.2016 18.6397 14.1635 17.1 14.1635Z"
      fill="#828282"
    />
  </svg>
</template>

<script>
export default {
  name: 'MaskIcon',
};
</script>
