import Vue from 'vue';

export const state = () => ({
  list: [],
  _byId: {},
});

export const getters = {
  all(state) {
    return state.list.map((id) => state._byId[id]);
  },

  byId(state) {
    return function (id) {
      return state._byId[id];
    };
  },
};

export const mutations = {
  setAll(state, items) {
    items.forEach((i) => setState(state, i));
    mapQuestionsOptions(state.list, state._byId);
  },
  set(state, obj) {
    setState(state, obj);
  },
};

export const actions = {
  getAll({ commit }) {
    return this.$axios.get('/question').then((res) => {
      commit('setAll', res.data);
    });
  },
  save({ commit }, { question }) {
    return this.$axios
      .post(`/question/${question._id}/save`, { question })
      .then((res) => {
        commit('set', res.data.question);

        return res.data.question;
      });
  },

  addConditionalQuestion(
    { commit },
    { option_id, question_id, conditional_question }
  ) {
    return this.$axios
      .post(`/question/${question_id}/${option_id}/add-conditional-question`, {
        conditional_question,
      })
      .then((res) => {
        commit('set', res.data.question);
        commit('set', res.data.root_question);

        return res.data.question;
      });
  },

  deleteConditionalQuestion(
    { commit },
    { option_id, question_id, conditional_question_id }
  ) {
    return this.$axios
      .post(
        `/question/${question_id}/${option_id}/delete-conditional-question`,
        { conditional_question_id }
      )
      .then((res) => {
        commit('set', res.data.root_question);

        return res.data.root_question;
      });
  },
};

function setState(state, obj) {
  obj.options.map((option) => mapQuestionOptions(option, state._byId));

  Vue.set(state._byId, obj._id, obj);

  if (!state.list.includes(obj._id)) {
    state.list.push(obj._id);
  }
}

function mapQuestionsOptions(q_list, q_byId) {
  q_list.map((q_id) => {
    const question = q_byId[q_id];
    question.options.map((option) => mapQuestionOptions(option, q_byId));
  });
}

function mapQuestionOptions(option, q_byId) {
  option.questions = option.questions.map((q) => {
    return q_byId[q] || q;
  });
}

function mapQuestion(state, q) {
  return state._byId(q) || q;
}
