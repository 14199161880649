export const state = () => ({
  allTodos: [],
  todo: {},
  coreTodos: [],
});

export const mutations = {
  setAll(state, todos) {
    state.allTodos = todos;
  },

  setTodo(state, todo) {
    state.todo = todo;
    if (state.allTodos.length == 0) {
      state.allTodos = [todo];
    }
  },

  setOneTodoInAll(state, todo) {
    const todoIndex = state.allTodos.findIndex((aT) => {
      return aT._id == todo._id;
    });
    if (todoIndex > -1) {
      state.allTodos.splice(todoIndex, 1, todo);
    } else {
      state.allTodos.push(todo);
    }
  },

  setCoreTodos(state, todos) {
    state.allTodos = todos.concat(state.allTodos);
  },

  setComplete(state, id) {
    const index = state.allTodos.findIndex((aT) => {
      return aT._id == id;
    });
    if (index > -1) {
      state.allTodos[index].status = 'complete';
    }
  },

  setIncomplete(state, id) {
    const index = state.allTodos.findIndex((aT) => {
      return aT._id == id;
    });
    if (index > -1) {
      state.allTodos[index].status = 'requested';
    }
  },

  setDefaultCoreTodos(state, core) {
    core ? (state.coreTodos = core.todos) : (state.coreTodos = []);
  },
};

export const getters = {
  getAll(state) {
    return state.allTodos;
  },

  byId(state) {
    return function (id) {
      return state.allTodos.filter((aT) => {
        return aT._company && aT._company._id == id;
      });
    };
  },
};

export const actions = {
  // Create
  async createTodo({ commit }, { todo }) {
    return await this.$axios.post('/ticket/create', { todo }).then((res) => {
      commit('setTodo', res.data);
      commit('setOneTodoInAll', res.data);

      return res.data;
    });
  },

  // Add Core To Dos
  async addCore({ commit }, { company, assignee }) {
    return await this.$axios
      .post('/ticket/addCore', { company,
assignee })
      .then((res) => {
        commit('setCoreTodos', res.data);

        return res.data;
      });
  },

  // GET all and byID
  async getAllTodos({ commit }) {
    return await this.$axios.get('/ticket/getAll').then((res) => {
      commit('setAll', res.data.todos);
      commit('setDefaultCoreTodos', res.data.coreTodos);

      return res.data.todos;
    });
  },

  async getTodoById({ commit }, todo_id) {
    return await this.$axios.get(`/ticket/getById/${todo_id}`).then((res) => {
      commit('setTodo', res.data);
      commit('setOneTodoInAll', res.data);

      return res.data;
    });
  },

  // COMPLETE by ID and undoCOMPLETE by ID
  async completeById({ commit }, todo_id) {
    return await this.$axios
      .post(`/ticket/completeById/${todo_id}`)
      .then((res) => {
        commit('setComplete', res.data);

        return res.data;
      });
  },

  async undoCompleteById({ commit }, todo_id) {
    return await this.$axios
      .post(`/ticket/undoCompleteById/${todo_id}`)
      .then((res) => {
        commit('setIncomplete', res.data);

        return res.data;
      });
  },

  // SKIP by ID
  async skipById({ commit }, { todo_id, todo }) {
    return await this.$axios
      .post(`/ticket/skippedById/${todo_id}`, { todo })
      .then((res) => {
        commit('setTodo', res.data);
        commit('setOneTodoInAll', res.data);

        return res.data;
      });
  },

  // SNOOZE by ID
  async snoozeById({ commit }, todo_id) {
    return await this.$axios
      .post(`/ticket/snoozeById/${todo_id}`)
      .then((res) => {
        commit('setTodo', res.data);
        commit('setOneTodoInAll', res.data);

        return res.data;
      });
  },

  // EDIT by ID
  async editById({ commit }, { todo_id, todo }) {
    return await this.$axios
      .post(`/ticket/editById/${todo_id}`, { todo })
      .then((res) => {
        commit('setTodo', res.data);
        commit('setOneTodoInAll', res.data);

        return res.data;
      });
  },
};
