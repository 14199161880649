import Vue from 'vue';

export const state = (default_state) => ({
  list: [],
  _byId: {},

  active: null,
  ...default_state,
});

export const getters = () => ({
  active(state) {
    return state._byId[state.active];
  },

  all(state) {
    return state.list.map((id) => state._byId[id]);
  },

  byId(state) {
    return function (id) {
      return state._byId[id];
    };
  },
});

export const mutations = () => ({
  setAll(state, items) {
    state.list = [];
    state._byId = {};
    items.forEach((i) => setState(state, i));
  },
  set(state, obj) {
    setState(state, obj);
  },
  setActive(state, id) {
    state.active = id;
  },
});

export const setState = function setState(state, obj) {
  Vue.set(state._byId, obj._id, obj);

  if (!state.list.includes(obj._id)) {
    state.list.push(obj._id);
  }
};
