import createApolloClient from '@/modules/apollo/createApolloClient';

// @ts-ignore
const companyApolloClient = createApolloClient({
  httpEndpoint: `${process.env.COMPANY_SERVICE_URL}/graphql`,
  httpOptions: {
    headers: { 'keep-alive': 'true' },
    credentials: 'include',
  },
  retryOptions: {
    delay: {
      initial: process.env.GQL_RETRY_DELAY_INTIAL,
      max: process.env.GQL_DELAY_MAX,
    },
    attempts: {
      max: process.env.GQL_RETRY_ATTEMPTS,
    },
  },
  timeout: process.env.GQL_TIMEOUT,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      partialRefetch: true,
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});


export default companyApolloClient;
