import { getModuleKeys } from '@/constants/Store';

export const BASE_URL = process.env.API_URL;
const { State, Mutations, Actions, Getters } = getModuleKeys('qna');

export const state = () => ({
  [State.DATA]: {},
});

export const mutations = {
  [Mutations.SET_ALL](state, items) {
    state[State.DATA] = items.reduce(
      (obj, item) => ({
        ...obj,
        [item._id]: item,
      }),
      state[State.DATA]
    );
  },
  [Mutations.SET](state, item) {
    state[State.DATA] = {
      ...state[State.DATA],
      [item._id]: item,
    };
  },
};

export const actions = {
  async [Actions.COMPLETE]({ commit }, id) {
    const { data } = await this.$axios.post(`/qna/v1/${id}/complete`, null, {
      baseURL: BASE_URL,
    });
    commit(Mutations.SET, data);

    return data;
  },

  async [Actions.CREATE]({ commit }, { user, type, title }) {
    const { data } = await this.$axios.post('/qna/create', {
      user,
      type,
      title,
    });
    commit('set', data);

    return data;
  },

  async [Actions.FETCH_BY_COMPANY]({ commit }, companyId) {
    const { data } = await this.$axios.get(`/qna/v1/byCompanyId/${companyId}`, {
      baseURL: BASE_URL,
    });
    commit('setAll', data);

    return data;
  },

  async [Actions.FETCH_BY_ID]({ commit }, id) {
    const { data } = await this.$axios.get(`/qna/v1/${id}`, {
      baseURL: BASE_URL,
    });
    commit('set', data);

    return data;
  },

  async [Actions.SAVE_ANSWERS](
    { commit },
    { answers = {}, progress = {}, id }
  ) {
    const { data } = await this.$axios.patch(
      `/qna/v1/${id}`,
      {
        answers,
        progress,
      },
      {
        baseURL: BASE_URL,
      }
    );
    commit('set', data);

    return data;
  },

  // Used by legacy surveys only. This differs from saveAnswers in that the
  // questions in legacy surveys are also mutable.
  async [Actions.UPDATE](
    { commit },
    { answers = {}, progress = {}, questions = {}, id }
  ) {
    const { data } = await this.$axios.post(
      `/qna/v1/${id}/update`,
      {
        answers,
        progress,
        questions,
      },
      {
        baseURL: BASE_URL,
      }
    );
    commit('set', data);

    return data;
  },
};

export const getters = {
  [Getters.ALL]: (state) => Object.values(state[State.DATA]),
  [Getters.BY_COMPANY]: (state) => (id) =>
    Object.values(state[State.DATA]).filter(
      (qna) => qna._company === id || qna._company.id === id
    ),
  [Getters.BY_COMPANY_AND_TYPE]: (state) => (id, type) =>
    Object.values(state[State.DATA]).find(
      (qna) =>
        (qna._company === id || qna._company.id === id) && qna.type === type
    ) || null,
  [Getters.BY_ID]: (state) => (id) => state[State.DATA][id] || null,
};
