import companyApolloClient from '@/modules/CompanyService/lib/company.apollo';
import payrollApolloClient from '@/modules/payroll/lib/payroll.apollo';
import trainingApolloClient from '@/modules/training/lib/training.apollo';
import { provide } from '@nuxtjs/composition-api';
import { ApolloClients } from '@vue/apollo-composable';
import { Client } from '@/modules/apollo/const';
import taskApolloClient from '@/modules/task/libs/task.apollo';
import billingApolloClient from '@/modules/Billing/gql/billing.apollo';
import employeeApolloClient from '@/modules/EmployeeVoices/services/employee.apollo';

export default ({ app }) => {
  app.setup = () => {
    provide(ApolloClients, {
      // default: companyApolloClient,
      [Client.BILLING]: billingApolloClient,
      [Client.COMPANY]: companyApolloClient,
      [Client.PAYROLL]: payrollApolloClient,
      [Client.TRAINING]: trainingApolloClient,
      [Client.TASK]: taskApolloClient,
      [Client.EMPLOYEE]: employeeApolloClient,
    });
  };
};
