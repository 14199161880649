












export default {
  name: 'IssueCollector',

  setup() {
    const buttons: { label: string; url: string }[] = [
      {
        label: 'Feature Request',
        url: `https://bambee.atlassian.net/servicedesk/customer/portal/4/group/13/create/161`,
      },
      {
        label: 'Report a Bug',
        url: `https://bambee.atlassian.net/servicedesk/customer/portal/4/group/13/create/153`,
      },
    ];

    return {
      buttons,
    };
  },
};
