import UsStates from '@/lib/globals/UsStates.json';
import phone from '@/lib/phone_validate';
import lodashGet from 'lodash/get';
import moment from 'moment';
import Vue from 'vue';
import VueCurrencyFilter from 'vue-currency-filter';
import VueNumeralFilter from 'vue-numeral-filter';

const CANCELLATION_SOURCES = {
  web: 'Web',
  email: 'Email',
  phone: 'Phone',
  chat: 'Chat',
  internal: 'Bambee Internal Decision',
};

const US_STATES = UsStates.reduce((hash, state) => {
  hash[state.abbreviation] = state.name;

  return hash;
}, {});

export default () => {
  Vue.use(VueNumeralFilter);
  Vue.use(VueCurrencyFilter, {
    symbol: '$',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: false,
  });

  Vue.filter('_get', (val, path, defaultVal) =>
    lodashGet(val, path, defaultVal)
  );
  Vue.filter('optional', (val) => val || '-');
  Vue.filter('capitalize', (val) => (val ? val.toUpperCase() : null));
  Vue.filter('date', (val, format = 'M/D/YYYY') =>
    val ? moment(val).format(format) : null
  );
  Vue.filter('dateTime', (val, format = 'M/D/YYYY hh:mm a') =>
    val ? moment(val).format(format) : null
  );
  Vue.filter('dateUTC', (val, format = 'M/D/YYYY') =>
    val ? moment.utc(val).format(format) : null
  );
  Vue.filter('dateLocal', (val, format = 'M/D/YYYY') =>
    val ? moment(val).format(format) : null
  );
  Vue.filter('time', (val, format = 'hh:mm a') =>
    val ? moment(val).format(format) : null
  );
  Vue.filter('dateAgo', (val) => (val ? moment(val).fromNow() : null));
  Vue.filter('pipLabel', (type) => {
    switch (type) {
      case 'pip':
        return 'Performance Plan';
      case 'written-warning':
        return 'Written Warning';
      case 'verbal-warning':
        return 'Verbal Warning';
      case 'incident':
        return 'Incident';
      case 'Pip':
        return 'Performance Plan';
      case 'Writtenwarning':
        return 'Written Warning';
      case 'Verbalwarning':
        return 'Verbal Warning';
      case 'Incident':
        return 'Incident';
      default:
        return type;
    }
  });
  Vue.filter('titleFilter', (val) => {
    if (val) {
      let str = val.split('_').join(' ');

      str = str.toLowerCase().split(' ');

      for (let i = 0; i < str.length; i += 1) {
        if (!['of', 'a', 'the', 'an', 'and'].includes(str[i])) {
          str[i] = str[i].split('');
          str[i][0] = str[i][0].toUpperCase();
          str[i] = str[i].join('');
        }
      }

      return str.join(' ');
    }

    return '';
  });

  Vue.filter('sanitizeHtml', (val) => {
    const htmlRegex = /(<([^>]+)>)/gi;
    if (val) {
      return val.replace(htmlRegex, '');
    }

    return '';
  });

  Vue.filter('fromAgo', (val) => {
    return moment(val).fromNow();
  });

  Vue.filter('phone', (val) => {
    return phone.format(val);
  });

  Vue.filter('companySizeMap', (val) => {
    switch (val) {
      case '25':
      case '20':
      case '20y':
        return '1-20';
      case '50':
      case '50y':
        return '21-50';
      case '150':
      case '150y':
        return '51-99';
      default:
        return '';
    }
  });

  Vue.filter('cancellationSource', (val) => {
    return (val && CANCELLATION_SOURCES[val]) || '-';
  });

  Vue.filter('stateName', (val) => {
    return (val && US_STATES[val]) || '';
  });
};
