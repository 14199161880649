import Vue from 'vue';

export const state = () => ({
  results: {},
});

export const getters = {
  results: (state, getters, rootState, rootGetters) => {
    return state.results[rootGetters.companyId] || [];
  },
};

export const actions = {

  getResults({ commit, rootGetters: { companyId } }) {
    return this.$axios
      .get(`/companies/v1/company/${companyId}/workflows/results`, {
        baseURL: `${process.env.API_URL}`,
      })
      .then((res) => {
        commit('setResults', {
          companyId,
          workflowResults: res.data,
        });
      });
  },

  setResultOutcome({ commit, rootGetters: { companyId } }, { workflowResultId, outcome }) {
    return this.$axios
      .patch(
        `/companies/v1/company/${companyId}/workflows/results/${workflowResultId}`,
        { outcome },
        { baseURL: `${process.env.API_URL}` }
      )
      .then((res) => {
        commit('updateResultOutcome', { companyId, workflowResultId, outcome });
      });
  },

};

export const mutations = {
  setResults(state, { companyId, workflowResults }) {
    Vue.set(state.results, companyId, workflowResults);
  },
  updateResultOutcome(state, { companyId, workflowResultId, outcome }) {
    const workflowResults = state.results[companyId] || [];
    const index = workflowResults.findIndex(wr => wr._id === workflowResultId);
    if (index >= 0) {
      workflowResults.splice(index, 1);
    }
  }
};
