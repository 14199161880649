export const state = () => ({});

export const actions = {
  reinstate(context, employeeId) {
    return this.$axios.post(
      `/employees/v1/employee/${employeeId}/reinstate`,
      null,
      {
        baseURL: `${process.env.API_URL}`,
      }
    );
  },
};
