import phoneFormatter from 'node-phone-formatter';
import phoneValidator from '@vtex/phone';
import '@vtex/phone/countries/USA';

const phone = {
  validate(phoneNumber) {
    if (!phoneNumber) {
      return {
        value: null,
        isValid: false,
      };
    }

    const number = phoneFormatter.format(phoneNumber, '+1 (NNN) NNN-NNNN');
    const tollFree = [
      '(800)',
      '(888)',
      '(877)',
      '(866)',
      '(855)',
      '(844)',
      '(833)',
    ];

    const isTollFree = number
      ? tollFree.some((ac) => number.includes(ac))
      : false;

    return {
      value: number,
      isValid: isTollFree || phoneValidator.validate(number, '1'),
    };
  },
  format(phoneNumber, format = '+1 (NNN) NNN-NNNN') {
    return phoneFormatter.format(phoneNumber, format);
  },
};

export default phone;
