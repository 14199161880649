export default ({ store, redirect, route }) => {
  if (!store.state.current_user) {
    return;
  }

  if (store.state.current_user.role !== 'partner') {
    return;
  }

  if (!route.path.includes('leads')) {
    return redirect('/leads');
  }
};
