<template>
  <v-app>
    <BaseNotifications />
    <v-navigation-drawer
      id="main-menu"
      v-model="drawer"
      app
      fixed
      clipped
      dark
      width="216"
    >
      <v-layout column fill-height>
        <v-list class="pt-0">
          <v-list-tile
            v-for="link in pageLinks"
            :key="link.title"
            :to="link.path"
            nuxt
          >
            <v-list-tile-action>
              <BaseIcon class="main-menu__icon" :icon="link.icon" />
            </v-list-tile-action>
            {{ link.title }}
          </v-list-tile>
        </v-list>

        <v-spacer />

        <v-list>
          <v-list-tile v-if="role == roles.ADMIN" nuxt to="/tools">
            <v-list-tile-action>
              <v-icon>build</v-icon>
            </v-list-tile-action>
            Tools
          </v-list-tile>
          <v-list-tile v-if="role == roles.ADMIN" nuxt to="/configuration">
            <v-list-tile-action>
              <BaseIcon
                class="main-menu__icon"
                icon="wrench"
              /> </v-list-tile-action
            >Configuration
          </v-list-tile>
          <v-list-tile nuxt to="/settings/profile">
            <v-list-tile-action>
              <BaseIcon
                class="main-menu__icon"
                icon="cogs"
              /> </v-list-tile-action
            >Settings
          </v-list-tile>
          <v-list-tile @click="handleLogout">
            <v-list-tile-action>
              <BaseIcon
                class="main-menu__icon"
                icon="signOut"
              /> </v-list-tile-action
            >Logout
          </v-list-tile>
        </v-list>
      </v-layout>
    </v-navigation-drawer>

    <v-toolbar
      app
      clipped-left
      clipped-right
      fixed
      dark
      class="primary elevation-2"
    >
      <v-toolbar-side-icon @click="drawer = !drawer" />

      <v-toolbar-title>
        <nuxt-link to="/">
          <v-img
            src="/images/logos/honey-logo.svg"
            height="36px"
            width="115px"
            contain
          />
        </nuxt-link>
      </v-toolbar-title>

      <div v-if="showRole" class="ml-4 hidden-md-and-down">
        <v-chip color="primary darken-1" text-color="white">
          <h1 class="subheading white--text">{{ getRoleName(role) }}</h1>
        </v-chip>
      </div>

      <v-spacer />

      <v-toolbar-items>
        <portal-target name="layout-toolbar-items" slim />
      </v-toolbar-items>
    </v-toolbar>

    <v-content>
      <IssueCollector />
      <nuxt />
    </v-content>

    <portal-target name="layout" />
  </v-app>
</template>

<script>
import { BaseIcon } from '@bambeehr/pollen';

import IssueCollector from '@/components/common/IssueCollector';
import BaseNotifications from '@/components/BaseNotifications/BaseNotifications.vue';
import { Roles, allHoneyRoles, hrAndGod } from '~/lib/roles';

export default {
  components: {
    BaseIcon,
    BaseNotifications,
    IssueCollector,
  },

  middleware: ({ route, store }) => {
    store.commit(
      'setCrumbs',
      route.meta.map((m) => m.crumb)
    );
  },

  data() {
    return {
      drawer: true,
      snackbar: false,
      roles: Roles,
    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.current_user;
    },

    role() {
      return this.$store.state.current_user
        ? this.$store.state.current_user.role
        : null;
    },

    showRole() {
      const rolesToShow = {
        sdr: Roles.SDR,
        sales: Roles.SALES,
        obs: Roles.OBS,
        csr: Roles.CSR,
        insurance: Roles.INSURANCE,
      };

      return this.role && rolesToShow[this.role];
    },

    canViewPayroll() {
      return !!this.$store.state?.current_user?.permissions?.canViewPayrollTab;
    },

    pageLinks() {
      const pages = [
        {
          path: '/customers',
          title: 'Customers',
          icon: 'building',
          canAccess: allHoneyRoles,
        },
        {
          path: '/policies',
          title: 'Global Policies',
          icon: 'clipboardCheck',
          canAccess: hrAndGod,
        },
        {
          path: '/users',
          title: 'Users',
          icon: 'users',
          canAccess: [Roles.ADMIN],
        },
        {
          path: '/payrolls',
          title: 'Payrolls',
          icon: 'moneySolid',
          canAccess: ['PAYROLL'],
        },
        {
          path: '/leads',
          title: 'Leads',
          icon: 'ear',
          canAccess: allHoneyRoles,
        },
        {
          path: '/cancellation',
          title: 'Cancellations',
          icon: 'ban',
          canAccess: allHoneyRoles,
        },
      ];

      return pages.filter(
        (page) =>
          page.canAccess.includes(this.role) ||
          (page.canAccess.includes('PAYROLL') && this.canViewPayroll)
      );
    },
  },

  mounted() {
    if (this.loggedIn) {
      this.$toast.show({
        text: `You are logged in as ${this.$store.state.current_user._auth.username}`,
        icon: 'computer',
        color: 'success',
        timeout: 1500,
        dismissible: true,
      });
    }
  },

  methods: {
    handleLogout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },

    getRoleName(role) {
      if (role === 'insurance') {
        return 'BAMBEE INSURANCE SERVICES';
      }

      return role.toUpperCase();
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
@import '~/assets/css/index.scss';

#main-menu {
  background: $gray-0;
}

#main-menu a[class*='active'] {
  background-color: #ffffff;

  &:hover {
    background-color: #ffffff;
  }
}

.main-menu {
  &__icon {
    font-size: 20px;
    margin-left: 0.5rem;
  }
}
</style>
