export const qna = Object.freeze({
  State: {
    DATA: 'qna/data',
  },
  Mutations: {
    SET_ALL: 'qna/setAll',
    SET: 'qna/set',
  },
  Actions: {
    COMPLETE: 'qna/complete',
    CREATE: 'qna/create',
    FETCH_BY_COMPANY: 'qna/fetchByCompany',
    FETCH_BY_ID: 'qna/fetchById',
    SAVE_ANSWERS: 'qna/saveAnswers',
    UPDATE: 'qna/update',
  },
  Getters: {
    ALL: 'qna/all',
    BY_COMPANY: 'qna/byCompany',
    BY_COMPANY_AND_TYPE: 'qna/byCompanyAndType',
    BY_ID: 'qna/byId',
  },
});

const Store = Object.freeze({
  qna,
});

// This returns a non-namespaced version of the above constants object. This is
// useful within store module files where method names are not namespaced.
export const getModuleKeys = (module) =>
  Object.entries(Store[module]).reduce(
    (moduleObj, [sectionKey, section]) => ({
      ...moduleObj,
      [sectionKey]: Object.entries(section).reduce(
        (sectionObj, [key, value]) => ({
          ...sectionObj,
          [key]: value.split('/').slice(1).join('/'),
        }),
        {}
      ),
    }),
    {}
  );

export default Store;
