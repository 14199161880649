/**
 * update code to follow g convention when converting this to DB collection
 * e.x. code: "admin" => code: "service03"
 */

export default [
  {
    code: 'account',
    externalText: 'Bambee Account',
    reasons: [
      {
        code: 'account01',
        externalText: 'Billing/Sales',
        active: true,
        internalOnly: false,
      },
      {
        code: 'insurance',
        externalText: 'Insurance',
        active: true,
        internalOnly: false,
      },
    ],
  },
  {
    code: 'service',
    externalText: 'Bambee Service',
    reasons: [
      {
        code: 'service01',
        externalText: 'Feature Request',
        active: true,
        internalOnly: false,
      },
      {
        code: 'service02',
        externalText: 'Out of Scope',
        active: true,
        internalOnly: false,
      },
      {
        code: 'admin',
        externalText: 'Platform Administration',
        active: true,
        internalOnly: false,
      },
      {
        code: 'service04',
        externalText: 'Positive Feedback',
        active: true,
        internalOnly: false,
      },
      {
        code: 'service05',
        externalText: 'Negative Feedback',
        active: true,
        internalOnly: false,
      },
      {
        code: 'service06',
        externalText: 'Cancellation Request',
        active: true,
        internalOnly: false,
      },
      {
        code: 'service07',
        externalText: 'Bug',
        active: true,
        internalOnly: false,
      },
      {
        code: 'service08',
        externalText: 'User Error',
        active: true,
        internalOnly: false,
      },
      {
        code: 'training',
        externalText: 'Platform Training',
        active: true,
        internalOnly: false,
      },
      {
        code: 'ssu',
        externalText: 'SSU Expectation Setting',
        active: true,
        internalOnly: false,
      },
    ],
  },
  {
    code: 'compliance',
    externalText: 'Compliance',
    reasons: [
      {
        code: 'compliance01',
        externalText: 'LOA/ADA',
        active: true,
        internalOnly: false,
      },
      {
        code: 'labor',
        externalText: 'Labor Claims',
        active: true,
        internalOnly: false,
      },
      {
        code: 'compliance',
        externalText: 'General',
        active: true,
        internalOnly: false,
      },
      {
        code: 'covid19',
        externalText: 'Coronavirus Assistance',
        active: true,
        internalOnly: false,
      },
    ],
  },
  {
    code: 'document',
    externalText: 'Document',
    reasons: [
      {
        code: 'policies',
        externalText: 'Policies – New Request',
        active: true,
        internalOnly: false,
      },
      {
        code: 'document02',
        externalText: 'Policies – Changes Requested',
        active: true,
        internalOnly: false,
      },
      {
        code: 'document03',
        externalText: 'Policies – Questions',
        active: true,
        internalOnly: false,
      },
      {
        code: 'handbook',
        externalText: 'Handbook',
        active: true,
        internalOnly: false,
      },
      {
        code: 'new',
        externalText: 'New Hire Package',
        active: true,
        internalOnly: false,
      },
      {
        code: 'w9',
        externalText: 'W9',
        active: true,
        internalOnly: false,
      },
      {
        code: 'document06',
        externalText: 'Other',
        active: true,
        internalOnly: false,
      },
    ],
  },
  {
    code: 'employee',
    externalText: 'Employee',
    reasons: [
      {
        code: 'employee01',
        externalText: 'Onboarding',
        active: true,
        internalOnly: false,
      },
      {
        code: 'furlough',
        externalText: 'Furlough',
        active: true,
        internalOnly: false,
      },
      {
        code: 'separation',
        externalText: 'Separation of Employment',
        active: true,
        internalOnly: false,
      },
      {
        code: 'employee03',
        externalText: 'Performance/Disciplinary Action',
        active: true,
        internalOnly: false,
      },
      {
        code: 'relations',
        externalText: 'Employee Relations',
        active: true,
        internalOnly: false,
      },
      {
        code: 'wages',
        externalText: 'Wage & Hour',
        active: true,
        internalOnly: false,
      },
      {
        code: 'employee06',
        externalText: 'Other',
        active: true,
        internalOnly: false,
      },
    ],
  },
  {
    code: 'logistics',
    externalText: 'Logistics',
    reasons: [
      {
        code: 'logistics01',
        externalText: 'Re-schedule',
        active: true,
        internalOnly: false,
      },
    ],
  },
  {
    code: 'other',
    externalText: 'Other',
    reasons: [
      {
        code: 'ee-training',
        externalText: 'Training & Development',
        active: true,
        internalOnly: false,
      },
      {
        code: 'roadmap',
        externalText: 'Roadmap',
        active: true,
        internalOnly: false,
      },
      {
        code: 'sales',
        externalText: 'Sales',
        active: true,
        internalOnly: false,
      },
      {
        code: 'hr-emergency',
        externalText: 'HR Emergency',
        active: true,
        internalOnly: false,
      },
      {
        code: 'other02',
        externalText: 'Other',
        active: true,
        internalOnly: false,
      },
    ],
  },
  {
    code: 'hbs',
    externalText: 'HBS',
    reasons: [
      {
        code: 'hbs01',
        externalText: 'Initial Call',
        active: true,
        internalOnly: false,
      },
      {
        code: 'hbs02',
        externalText: 'Draft delivered',
        active: true,
        internalOnly: false,
      },
      {
        code: 'hbs03',
        externalText: 'Changes requested',
        active: true,
        internalOnly: false,
      },
      {
        code: 'hbs04',
        externalText: 'Completed',
        active: true,
        internalOnly: false,
      },
      {
        code: 'hbs05',
        externalText: 'Other',
        active: true,
        internalOnly: false,
      },
    ],
  },
  {
    code: 'obs',
    externalText: 'OBS',
    reasons: [
      {
        code: 'obs01',
        externalText: 'Onboarding',
        active: true,
        internalOnly: false,
      },
      {
        code: 'obs',
        externalText: 'Call Request',
        active: true,
        internalOnly: false,
      },
      {
        code: 'obs03',
        externalText: 'Re-schedule',
        active: true,
        internalOnly: false,
      },
      {
        code: 'obs04',
        externalText: 'Transition',
        active: true,
        internalOnly: false,
      },
      {
        code: 'sf',
        externalText: 'Salesforce Onboarding',
        active: true,
        internalOnly: true,
      },
    ],
  },
  {
    code: 'csr',
    externalText: 'CSR',
    reasons: [
      {
        code: 'csr',
        externalText: 'Mainline Call',
        active: true,
        internalOnly: false,
      },
    ],
  },
  {
    code: 'none',
    externalText: 'None',
    reasons: [
      {
        code: 'none',
        externalText: 'None',
        active: true,
        internalOnly: false,
      },
    ],
  },
];
