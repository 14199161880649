import dayjs from 'dayjs';
import Vue from 'vue';

export const state = () => ({
  data: {
    all: [],
    current: null,
    charges: null,
    invoices: [],
    sharedSubCompanies: [],
  },
});

function moveSetupChargeFirst(invoices) {
  const indexOfSetupFee = invoices.findIndex(
    (invoice) => invoice.type === 'setupFee'
  );
  if (indexOfSetupFee > 0) {
    const invoiceForSetupFee = invoices[indexOfSetupFee];
    invoices.splice(indexOfSetupFee, 1);
    invoices.unshift(invoiceForSetupFee);
  }

  return invoices;
}

export const mutations = {
  setAll(state, cancellations) {
    state.data.all = cancellations;
  },
  setOneInAllById(state, cancellation) {
    const index = state.data.all.findIndex((a) => a._id == cancellation._id);
    state.data.all.splice(index, 1, {
      _id: cancellation._id,
      companyId: cancellation._company._id,
      companyName: cancellation._company.name,
      requestedByName: cancellation._requestedBy.profile.full_name,
      source: cancellation.source,
      reasonCodes: cancellation.reasonCodes,
      status: cancellation.status,
      requestedDate: cancellation.requestedDate,
    });
  },
  setCurrent(state, cancellation) {
    state.data.current = cancellation;
  },
  setCharges(state, charges) {
    charges.charges = charges.charges || [];
    charges.skipped = charges.skipped || [];
    state.data.charges = charges;

    const invoices = charges.charges
      .sort((a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf())
      .map((charge) => {
        charges.invoice = charges.invoice || [];

        return charge.invoice.map((i) => {
          const item = {
            _id: i.id,
            chargeId: charge.id,
            date: charge.date,
            amount: i.amount ? parseFloat(i.amount) : 0,
            setupFee: i.setupFee ? parseFloat(i.setupFee) : 0,
            status: charge.status,
            refunded: charge.refunded,
            disputed: charge.disputed,
          };
          item.type = item.setupFee === 0 ? 'subscription' : 'setupFee';

          return item;
        });
      })
      .reduce((acc, val) => acc.concat(val), []);
    state.data.invoices = moveSetupChargeFirst(invoices);

    const skipped = charges.skipped
      .sort((a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf())
      .map((charge) =>
        charge.items.map((i) => {
          const item = {
            invoice: charge.invoice,
            date: charge.date,
            amount: i.amount ? parseFloat(i.amount) : 0,
            setupFee: i.setupFee ? parseFloat(i.setupFee) : 0,
            status: 'skipped',
          };
          item.type = item.setupFee == 0 ? 'subscription' : 'setupFee';

          return item;
        })
      )
      .reduce((acc, val) => acc.concat(val), []);
    state.data.skipped = moveSetupChargeFirst(skipped);
  },

  // SET data for components
  setCancelData(state, cancellation) {
    // const cancelData = state.data.current
    state.data.current = {
      // ...cancelData,
      ...cancellation,
    };
  },
  setCancelValue(state, { fieldName, value }) {
    state.data.current[fieldName] = value;
  },
  setInvoiceValue(state, { id, value }) {
    let booleanValue;
    value ? (booleanValue = value) : (booleanValue = false);
    const index = state.data.invoices.findIndex((invoice) => invoice._id == id);
    if (index >= 0) {
      Vue.set(state.data.invoices[index], 'value', booleanValue);
    }
  },

  resetInvoice(state) {
    state.data.invoices.forEach((i) => {
      i.value = false;
    });
  },

  setSharedSub(state, { sharedCompanies }) {
    state.data.sharedSubCompanies = sharedCompanies;
  },
};

export const getters = {
  allCancellations(state) {
    return state.data.all;
  },
  currentCancellation(state) {
    return state.data.current;
  },
};

export const actions = {
  async getById({ commit }, cancellationId) {
    return this.$axios
      .get(`/cancellation/getById/${cancellationId}`)
      .then((res) => {
        commit('setCurrent', res.data);
      });
  },

  // Create
  async createCancellation({ commit }, { notes, reasonCodes }) {
    return await this.$axios
      .post('/cancellation/cancellation-request', { notes, reasonCodes })
      .then((res) => {
        commit('setCurrent', res.data);
      });
  },

  adminCreateCancellation({ state, commit }, { companyId, requestedEmail }) {
    return this.$axios
      .post('/cancellation/', {
        note: state.data.current.internalReasonNote,
        reasonCodes: state.data.current.reasonCodes,
        source: state.data.current.source,
        requestedBy: state.data.current._requestedBy._id,
        requestedDate: state.data.current.requestedDate,
        companyId,
        requestedEmail,
      })
      .then((res) => {
        commit('setCurrent', res.data);

        return res.data;
      });
  },

  async getAll({ commit }) {
    return this.$axios.post('/cancellations/v1/list').then((res) => {
      commit('setAll', res.data);
    });
  },

  getByCompanyId({ commit }, { companyId }) {
    return this.$axios
      .get(`/cancellation/getByCompanyId/${companyId}`)
      .then((res) => {
        commit('setCurrent', res.data);
      })
      .catch((e) => {
        if (e.response.status == 404) {
          return;
        }

        throw e;
      });
  },

  saveData({ state, commit }) {
    const cancellationId = state.data.current._id;
    const payload = {
      source: state.data.current.source,
      reasonCodes: state.data.current.reasonCodes,
      requestedBy: state.data.current._requestedBy,
      requestedDate: state.data.current.requestedDate,
      internalReasonNote: state.data.current.internalReasonNote,
      closingNote: state.data.current.closingNote,
    };

    return this.$axios
      .post(`/cancellation/getById/${cancellationId}`, { payload })
      .then((res) => {
        commit('setOneInAllById', res.data);
        commit('setCurrent', res.data);

        return res.data;
      });
  },

  resolveCancellation(
    { state, commit },
    {
      status,
      refundAmount = 0,
      reinstatementAmount = 0,
      creditMonths = 0,
      closingNote,
      invoices,
      cancelEffectiveDate,
      confirmedEmail,
    }
  ) {
    const companyId = state.data.current._company._id;
    const cancellationId = state.data.current._id;

    return this.$axios
      .post(`/cancellation/resolve-cancellation/${cancellationId}`, {
        status,
        closingNote,
        refundAmount,
        reinstatementAmount,
        creditMonths,
        companyId,
        invoices,
        cancelEffectiveDate,
        confirmedEmail,
      })
      .then((res) => {
        commit('setOneInAllById', res.data.cancellation);
        commit('setCurrent', res.data.cancellation);

        return res.data.mappedData;
      });
  },

  reverseCancellation({ commit }, { companyId }) {
    return this.$axios
      .post(
        `/companies/v1/company/${companyId}/cancellations/reverse`,
        {},
        {
          baseURL: `${process.env.API_URL}`,
        }
      )
      .then((res) => {
        commit('setOneInAllById', res.data.cancellation);
        commit('setCurrent', res.data.cancellation);

        return res.data;
      });
  },

  changeEffectiveDate({ commit }, { companyId, effectiveDate }) {
    return this.$axios
      .put(
        `/companies/v1/company/${companyId}/cancellations/effective-date`,
        {
          effectiveDate,
        },
        {
          baseURL: `${process.env.API_URL}`,
        }
      )
      .then((res) => {
        commit('setOneInAllById', res.data.cancellation);
        commit('setCurrent', res.data.cancellation);

        return res.data;
      });
  },

  getCharges({ commit }, { companyId }) {
    return this.$axios.get(`/cancellation/charges/${companyId}`).then((res) => {
      commit('setCharges', res.data);

      return res.data;
    });
  },

  getSharedSubCompanies({ commit }, { companyId }) {
    return this.$axios
      .get(`/cancellation/connected-companies-subscription/${companyId}`)
      .then((res) => {
        commit('setSharedSub', { sharedCompanies: res.data });

        return res.data;
      })
      .catch((err) => err.response);
  },
};
