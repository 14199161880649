import Vue from 'vue';
import sortBy from 'lodash/sortBy';
import keyBy from 'lodash/keyBy';
import { DocumentTypes } from '@/constants/documents';

export const state = () => ({
  documents: {},
  fullDocuments: {},
  globalTemplates: {},
});

export const getters = {
  /**
   * Documents
   */
  getById: (state) => (documentId) => state.documents[documentId],

  /**
   * Global Templates
   */
  getGlobalTemplateById: (state) => (templateId) =>
    state.globalTemplates[templateId],
  getGlobalTemplates: (state) =>
    sortBy(
      Object.values(state.globalTemplates).filter((doc) => doc.active),
      'name'
    ),
  getGlobalTemplatesArchived: (state) =>
    sortBy(
      Object.values(state.globalTemplates).filter((doc) => !doc.active),
      'name'
    ),
  getBambeeGlobalTemplates: (state, getters) =>
    getters.getGlobalTemplates.filter(
      (doc) => doc.type === DocumentTypes.BAMBEE
    ),
  getPandaGlobalTemplates: (state, getters) =>
    getters.getGlobalTemplates.filter(
      (doc) => doc.type === DocumentTypes.PANDA
    ),
};

export const actions = {
  /**
   * Documents
   */
  fetchDocumentById({ state, dispatch }, documentId) {
    if (documentId in state.fullDocuments && documentId in state.documents) {
      return Promise.resolve();
    }

    return dispatch('getDocumentById', documentId);
  },
  getDocumentById({ commit }, documentId) {
    return this.$axios.get(`/document/${documentId}`).then((res) => {
      commit('saveDocument', {
        documentId,
        document: res.data,
      });
    });
  },
  rename({ commit }, { documentId, name }) {
    return this.$axios
      .put(`/document/${documentId}/rename`, { name })
      .then((res) => {
        commit('saveDocumentPartial', {
          documentId,
          partial: { name },
        });

        return res;
      });
  },

  /**
   * Global Templates
   */
  fetchGlobalTemplates({ commit }) {
    return this.$axios.get(`/document/template/list`).then((res) => {
      commit('setGlobalTemplates', res.data);

      return res.data;
    });
  },
  fetchGlobalTemplatesArchived({ commit }) {
    return this.$axios.get(`/document/template/list-archived`).then((res) => {
      commit('setGlobalTemplates', res.data);

      return res.data;
    });
  },
  async archiveGlobalTemplate({ commit }, { templateId }) {
    await this.$axios.post(
      `/document/template/${templateId}/archive`,
      templateId
    );

    commit('toggleArchiveTemplate', {
      templateId,
      active: false,
    });
  },
  async unarchiveGlobalTemplate({ commit }, { templateId }) {
    await this.$axios.post(
      `/document/template/${templateId}/unarchive`,
      templateId
    );

    commit('toggleArchiveTemplate', {
      templateId,
      active: true,
    });
  },
};

export const mutations = {
  /**
   * Documents
   */
  saveDocument(state, { documentId, document }) {
    Vue.set(state.documents, documentId, document);
    Vue.set(state.fullDocuments, documentId, true);
  },
  saveDocumentPartial(state, { documentId, partial }) {
    if (!(documentId in state.documents)) {
      state.documents[documentId] = {};
    }

    Object.entries(partial).forEach(([key, value]) => {
      Vue.set(state.documents[documentId], key, value);
    });
  },

  /**
   * Global Templates
   */
  setGlobalTemplates(state, templates) {
    const addition = keyBy(templates, '_id');

    state.globalTemplates = {
      ...state.globalTemplates,
      ...addition,
    };
  },
  toggleArchiveTemplate(state, { templateId, active }) {
    if (state.globalTemplates[templateId]) {
      Vue.set(state.globalTemplates[templateId], 'active', active);
    }
  },
};
