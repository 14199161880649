import { getCookie, setCookie } from '@/lib/cookie-helpers';

const COOKIE_NAME = 'trustpilot-token';
// const emailTemplateId = '529c0abfefb96008b894ad02' // tobe used later when we have custom templates;
const businessUnitId = '5849d8c10000ff000598d54e';

let TPClient;

export function authenticate() {
  const { origin, pathname } = window.location;
  const redirectURI = `${origin}/oauth/trustpilot/callback?redir=${pathname}`;

  window.location.href = `https://authenticate.trustpilot.com?client_id=${
    process.env.TRUSTPILOT_KEY
  }&redirect_uri=${encodeURIComponent(redirectURI)}&response_type=token`;
}
export function setAccessToken(token) {
  TPClient.setToken(token, 'Bearer');
  setCookie(COOKIE_NAME, token);
}

export async function requestReview(
  { email, name, userId, inviterId },
  tags = []
) {
  const override = process.env.TRUSTPILOT_CONSUMER_EMAIL_OVERRIDE
    ? `${
        process.env.TRUSTPILOT_CONSUMER_EMAIL_OVERRIDE
      }+${new Date().getTime()}@bambee.com`
    : null;

  const sanitizedTags = tags
    .filter((t) => t)
    .map((t) => t.replace(/[^\w.-]+/g, '-'));

  const payload = {
    consumerEmail: override || email,
    consumerName: name,
    referenceNumber: userId,
    locale: 'en-US',

    serviceReviewInvitation: {
      tags: sanitizedTags,
      redirectUri: 'https://app.bambee.com',
      // templateId: emailTemplateId, // to be used when we have our custom email templates
    },
  };

  await TPClient.post(
    `https://invitations-api.trustpilot.com/v1/private/business-units/${businessUnitId}/email-invitations`,
    payload
  );

  window.analytics.track('trustpilot-review-invitation', {
    email,
    name,
    userId,
    inviterId,
  });
}

export default async ({ $axios }) => {
  TPClient = $axios.create();
  TPClient.onError((err) => {
    if (err?.response?.status === 401) {
      authenticate();
    }
  });
  const cookie = getCookie(COOKIE_NAME);

  if (cookie) {
    setAccessToken(cookie);
  }
};
