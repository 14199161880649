<template>
  <MeerkatNotifications
    style="z-index: 9999"
    :notifications="meerkatNotifications"
    position="bottom-center"
    @close="removeNotification"
  />
</template>

<script>
import { computed, watch } from '@nuxtjs/composition-api';
import { MeerkatNotifications } from '@bambeehr/pollen';
import { Timer } from '@/modules/payroll/constants/payroll';
import { useNotifications, NotificationTypes } from '@/hooks/useNotifications';
import useErrorHandler, { ErrorCategories } from '@/hooks/useErrorHandler';

export default {
  name: 'BaseNotifications',
  components: {
    MeerkatNotifications,
  },
  setup() {
    const {
      notifications,
      addError,
      remove: removeNotification,
    } = useNotifications();
    const errorHandler = useErrorHandler();

    const promoteErrorTypeToNotifications = (errors, errorType) => {
      const filteredErrors = errors.filter((err) => err.category === errorType);

      filteredErrors.forEach((err) => {
        addError(err.message);
        errorHandler.resolve(err.id);
      });
    };

    watch(errorHandler.errors, (newVal) => {
      if (!newVal?.length) {
        return;
      }

      promoteErrorTypeToNotifications(newVal, ErrorCategories.API);

      // Present all errors as notification when not in production
      if (process.env.NODE_ENV !== 'production') {
        promoteErrorTypeToNotifications(newVal, ErrorCategories.UNCAUGHT);
      }
    });

    const meerkatNotifications = computed(() => {
      return notifications.value
        .map(({ id, message = '', title = '', type, meta }) => {
          if (!message && !title) {
            return null;
          }

          const finalMsg = [title, message].filter((x) => !!x).join('\n');

          let variant;
          switch (type) {
            case NotificationTypes.FATAL:
            case NotificationTypes.ERROR:
              variant = 'error';
              break;

            case NotificationTypes.SUCCESS:
              variant = 'secondary';
              break;

            default:
              variant = 'default';
              break;
          }

          const { timer = Timer.MEDIUM } = meta;

          return {
            id,
            message: finalMsg,
            timer,
            variant,
            actions: [
              {
                label: 'Close',
                handler: () => removeNotification(id),
              },
            ],
          };
        })
        .filter((x) => !!x);
    });

    return {
      meerkatNotifications,
      removeNotification,
    };
  },
};
</script>
