import * as bare from '@/lib/bare_list_store';
import { allHoneyRoles, Roles } from '@/lib/roles';
import Vue from 'vue';

export const state = () =>
  bare.state({
    employees: [],
    employee: {},
    cabinet: [],
    tickets: [],
    folder: {},
    employeesLoading: {},
  });

export const getters = bare.getters();
export const mutations = bare.mutations();

getters.getCompanyAdmins = (state) => {
  return state.employees.filter((e) => e.role === Roles.USER);
};

getters.getDesignatedSigner = (state, getters) => {
  return function (customerId) {
    return getters.byId(customerId)._company._signer;
  };
};

// TODO: move to users - currently not populated
getters.getEmployeeById = (state) => (id) => {
  return state.employees.find((e) => e._id === id);
};

mutations.setEmployees = (state, employees) => {
  state.employees = employees;
};

mutations.setEmployee = (state, employee) => {
  state.employee = employee;
};

mutations.setEmployeeMasq = (state, { userId, masq }) => {
  const employeeIndex = state.employees.findIndex((u) => u._id === userId);
  const employee = state.employees[employeeIndex];

  if (!employee) {
    return;
  }
  employee.masq = masq;
  Vue.set(state.employees, employeeIndex, employee);
};

mutations.setCabinet = (state, cabinet) => {
  state.cabinet = cabinet;
};

mutations.setFolder = (state, folder) => {
  state.folder = folder;
};

mutations.setCustomerRoadmap = (state, { customer, roadmap }) => {
  customer._company.roadmap_steps = roadmap.filter(
    (step) => step.name !== 'account setup'
  );
};

mutations.setCustomerCompany = (state, { userId, company }) => {
  const customer = state._byId[userId];
  customer._company = company;
};

mutations.setTickets = (state, tickets) => {
  state.tickets = tickets;
};

mutations.setEvents = (state, events) => {
  state.events = events;
};

mutations.updateCurrentCustomerCompany = (state, { customerId, company }) => {
  Vue.set(state._byId[customerId], '_company', company);
};

mutations.updateDeliquentStatus = (state, { customerId, status }) => {
  const company = state._byId[customerId]._company;
  Vue.set(company, 'delinquent', status.delinquent);
  Vue.set(company.stripe.customer, 'delinquent', status.delinquent);
  Vue.set(
    company.stripe,
    'original_payment_date',
    status.original_payment_date
  );
  Vue.set(
    company.stripe,
    'expected_payment_date',
    status.expected_payment_date
  );
  Vue.set(company.stripe, 'attempt_count', status.attempt_count);
};

mutations.setCustomerCompanySettings = (
  state,
  { customerId, property, value }
) => {
  const customer = state._byId[customerId];

  if (!customer) {
    return;
  }

  const company = customer._company;

  company.settings[property] = value;
};

export const actions = {
  fetch({ state, dispatch }, id) {
    if (state.employeesLoading[id]) {
      return state.employeesLoading[id];
    }

    return dispatch('get', id);
  },
  get({ state, commit }, id) {
    state.employeesLoading[id] = this.$axios
      .get(`/customer/${id}`)
      .then((res) => {
        commit('set', res.data);
        commit(
          'companies/setCompany',
          { company: res.data._company },
          { root: true }
        );

        return res.data;
      })
      .finally(() => {
        state.employeesLoading[id] = false;
      });

    return state.employeesLoading[id];
  },

  getAll({ commit }) {
    return this.$axios.get('/customer').then((res) => {
      const users = res.data;
      commit('setAll', users);

      return users;
    });
  },

  /**
   * Wrapper for customers/setCustomerCompany and companies/setCompany
   *
   * TODO: move all company updates to companies store and remove
   */
  updateCompanyData({ commit }, { userId, company }) {
    commit('setCustomerCompany', {
      userId,
      company,
    });
    commit('companies/setCompany', { company }, { root: true });
  },

  markTest({ commit }, { test, userId }) {
    const link = test ? 'mark-test' : 'mark-not-test';

    return this.$axios.post(`/customer/${userId}/${link}`).then((res) => {
      commit('set', res.data);
      commit(
        'companies/setCompanyPartial',
        {
          companyId: res.data._company._id,
          partial: {
            test: res.data._company.test,
          },
        },
        { root: true }
      );

      return res.data;
    });
  },

  // TODO: switch to companies/getCompanyUsers
  getEmployees({ commit }, userId) {
    return this.$axios.get(`/customer/${userId}/employees`).then((res) => {
      const employees = res.data;
      commit('setEmployees', employees);

      return employees;
    });
  },

  getEmployee({ commit }, userId) {
    return this.$axios.get(`/customer/employee/${userId}`).then((res) => {
      const employee = res.data;
      commit('setEmployee', employee);

      return employee;
    });
  },

  async getMasq({ commit }, { userId, userRole }) {
    const response = await this.$axios.post(
      `/auth/v3/masquerade`,
      { userId },
      {
        baseURL: process.env.PUBLICAPI_URL,
        withCredentials: true,
      }
    );

    const { accessToken } = response.data;
    const baseUrl = allHoneyRoles.includes(userRole)
      ? window.location.host
      : window.location.host.replace('honey', 'app');
    const masq = `${baseUrl}?token=${accessToken}`;

    commit('setEmployeeMasq', {
      userId,
      masq,
    });

    return masq;
  },

  getCabinet({ commit }, userId) {
    return this.$axios.get(`/customer/${userId}/company-folder`).then((res) => {
      commit('setFolder', res.data);

      return res.data;
    });
  },

  deleteFolder({ commit }, { user_id: userId, folder_id: folderId }) {
    return this.$axios
      .post(`/customer/${userId}/folder/${folderId}/delete`)
      .then((res) => {
        commit('setFolder', res.data);

        return res.data;
      });
  },

  updateFolderPermission(
    { commit },
    { user_id: userId, folder_id: folderId, visibility }
  ) {
    return this.$axios
      .post(`/customer/${userId}/folder/${folderId}/update-permission`, {
        visibility,
      })
      .then((res) => {
        commit('setFolder', res.data);

        return res.data;
      });
  },

  updateEmployeeProfile({ commit }, { profile, user_id: userId, contractor }) {
    return this.$axios
      .post(`/customer/${userId}/update-profile`, {
        profile,
        contractor,
      })
      .then((res) => {
        const employee = res.data;

        commit('setEmployee', employee);

        return employee;
      });
  },

  updateEmployeeRole({ commit }, { user_id: userId, role }) {
    return this.$axios
      .post(`/customer/${userId}/update-role`, { role })
      .then((res) => {
        const employee = res.data;
        commit('setEmployee', employee);

        return employee;
      });
  },

  updateEmployeePermission({ commit }, { user_id: userId, permission, value }) {
    return this.$axios
      .post(`/customer/${userId}/update-permission`, {
        permission,
        value,
      })
      .then((res) => {
        const employee = res.data;

        commit('setEmployee', employee);

        return employee;
      });
  },

  updateEmployeeState({ commit }, { user_id: userId, state, value }) {
    return this.$axios
      .post(`/customer/${userId}/update-state`, {
        state,
        value,
      })
      .then((res) => {
        const employee = res.data;

        commit('setEmployee', employee);

        return employee;
      });
  },

  updateCompanyName({ commit }, { name, user_id: userId }) {
    return this.$axios
      .post(`/customer/${userId}/update-company-name`, { name })
      .then((res) => {
        const customer = res.data;
        commit('set', customer);

        return customer;
      });
  },

  updateCustomerSales({ commit }, { customerId, salesId }) {
    return this.$axios
      .post(`/customer/${customerId}/update-sales`, { sales: salesId })
      .then((res) => {
        const customer = res.data;
        commit('set', customer);

        return customer;
      });
  },

  updateCompanyProfile({ commit }, { profile, user_id: userId }) {
    return this.$axios
      .post(`/customer/${userId}/update-company-profile`, { profile })
      .then((res) => {
        const customer = res.data;
        commit('set', customer);

        return customer;
      });
  },

  enableApprovers({ commit }, { user }) {
    return this.$axios
      .post(`/customer/${user._id}/enable-approvers`)
      .then((res) => {
        const customer = res.data;
        commit('set', customer);

        return customer;
      });
  },

  disableApprovers({ commit }, { user }) {
    return this.$axios
      .post(`/customer/${user._id}/disable-approvers`)
      .then((res) => {
        const customer = res.data;
        commit('set', customer);

        return customer;
      });
  },

  toggleHidePayRate({ commit }, { value, customerId, companyId }) {
    return this.$axios
      .put(
        `/companies/v1/company/${companyId}/settings/hide-pay-rate`,
        { value },
        {
          baseURL: `${process.env.API_URL}`,
        }
      )
      .then(() => {
        commit('setCustomerCompanySettings', {
          value,
          customerId,
          property: 'hidePayRate',
        });
      });
  },

  updateRoadmapStep({ commit, getters, dispatch }, { user_id: userId, step }) {
    return this.$axios
      .post(`/customer/${userId}/roadmap/update-step`, { step })
      .then((res) => {
        const roadmap = res.data;
        const customer = getters.byId(userId);

        commit('setCustomerRoadmap', {
          customer,
          roadmap,
        });

        dispatch('advisor/get', null, { root: true });

        return roadmap;
      });
  },

  rescheduler(
    { commit, getters, dispatch },
    { user_id: userId, step, dateTime, phone }
  ) {
    return this.$axios
      .post(`/customer/${userId}/reschedule-call`, {
        step,
        schedule: dateTime,
        phone,
      })
      .then((res) => {
        const roadmap = res.data;
        const customer = getters.byId(userId);

        commit('setCustomerRoadmap', {
          customer,
          roadmap,
        });
        dispatch('advisor/get', null, { root: true });

        return roadmap;
      });
  },

  createSubscription(
    { dispatch },
    {
      token,
      user_id: userId,
      company_id: companyId,
      trial,
      plan,
      setupFee,
      couponId,
      isPayrollClient,
    }
  ) {
    return this.$axios
      .post(
        `/${companyId}/plan/convert`,
        {
          token,
          trial,
          plan,
          setupFee,
          couponId,
          isPayrollClient,
        },
        {
          baseURL: `${process.env.PUBLICAPI_URL}/billing/companies/v1/company`,
        }
      )
      .then((res) => {
        dispatch('updateCompanyData', {
          userId,
          company: res.data,
        });

        return res.data;
      });
  },

  updateCreditCard({ commit }, { token, user_id: userId }) {
    return this.$axios
      .post(`/customer/${userId}/update-credit-card`, { token })
      .then((res) => {
        commit('setCustomerCompany', {
          userId,
          company: res.data,
        });
      });
  },

  reactivateSubscription(
    { commit },
    { plan, user_id: userId, setup_fee_percentage: setupFeePercentage }
  ) {
    return this.$axios
      .post(`/customer/${userId}/reactivate-subscription`, {
        plan,
        setup_fee_percentage: setupFeePercentage,
      })
      .then((res) => {
        commit('setCustomerCompany', {
          userId,
          company: res.data,
        });
      });
  },

  importStripeSubscription(
    { dispatch },
    { userId, companyId, stripeCustomerId, enableOnboarding }
  ) {
    return this.$axios
      .post(
        `/${companyId}/plan/import-stripe-data`,
        {
          stripeCustomerId,
          enableOnboarding,
        },
        {
          baseURL: `${process.env.API_URL}/companies/v1/company`,
        }
      )
      .then((res) => {
        dispatch('updateCompanyData', {
          userId,
          company: res.data,
        });
      });
  },

  updateStripeData(_, { userId, stripeCustomerId }) {
    return this.$axios.post(`/customer/${userId}/import-stripe-data`, {
      stripeCustomerId,
    });
  },

  createUser(_, { user, companyId }) {
    return this.$axios
      .post(`/companies/v1/company/${companyId}/employees/employee`, user, {
        baseURL: process.env.API_URL,
      })
      .then((res) => {
        return res.data;
      });
  },
  bulkCreateUsers(_, { users, companyId }) {
    return this.$axios.post(
      `/companies/v1/company/${companyId}/employees`,
      users,
      { baseURL: process.env.API_URL }
    );
  },

  addNote({ commit }, { user, text, needs, employees, pinned }) {
    return this.$axios
      .post(`/customer/${user._id}/add-note`, {
        text,
        needs,
        employees,
        pinned: !!pinned,
      })
      .then((res) => {
        const customer = res.data;
        commit('set', customer);

        return customer;
      });
  },

  editNote({ commit }, { user, needs, text, employees, id, pinned }) {
    return this.$axios
      .post(`/customer/${user._id}/edit-note`, {
        text,
        needs,
        employees,
        id,
        pinned,
      })
      .then((res) => {
        const customer = res.data;
        commit('set', customer);
      });
  },

  createLead({ commit }, { user, company, accountExecutive }) {
    return this.$axios
      .post(`/customer/create-lead`, {
        user,
        company,
        accountExecutive,
      })
      .then((res) => {
        const customer = res.data;

        commit('set', customer);

        return customer;
      });
  },

  updateLeadStatus({ commit }, { userId, leadStatus, leadStatusReason }) {
    return this.$axios
      .post(`/customer/${userId}/update-lead-status`, {
        leadStatus,
        leadStatusReason,
      })
      .then((res) => {
        commit('setCustomerCompany', {
          userId,
          company: res.data,
        });

        return res.data;
      });
  },

  getTickets({ commit }, { customer_id: customerId }) {
    return this.$axios.get(`/customer/${customerId}/tickets`).then((res) => {
      commit('setTickets', res.data);

      return res.data;
    });
  },

  getEvents({ commit }, { customerId }) {
    return this.$axios.get(`/customer/${customerId}/events`).then((res) => {
      commit('setEvents', res.data);
    });
  },

  overviewCallChange({ commit }, { userId, overview }) {
    return this.$axios
      .post(`/customer/${userId}/change-overview-status`, { overview })
      .then((res) => {
        commit('setCustomerCompany', {
          userId,
          company: res.data,
        });
      });
  },

  savePersona({ commit }, { userId, difficulty, helpRequired, expectations }) {
    return this.$axios
      .post(`/customer/${userId}/save-persona`, {
        difficulty,
        helpRequired,
        expectations,
      })
      .then((res) => {
        commit('set', res.data);
      });
  },

  resetForDemo({ commit }, { user }) {
    return this.$axios
      .post(`/customer/${user._id}/reset-for-demo`, { user })
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  updateDesignated({ commit }, { userId, signer }) {
    return this.$axios
      .post(`/customer/${userId}/update-designated-signer`, { signer })
      .then((res) => {
        commit('setCustomerCompany', {
          userId,
          company: res.data,
        });
      });
  },

  showReportCard({ commit }, { user_id: userId, toggle }) {
    return this.$axios
      .post(`/customer/${userId}/show-report-card`, { toggle })
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  managerCanGrade({ commit }, { user_id: userId, toggle }) {
    return this.$axios
      .post(`/customer/${userId}/manager-can-grade`, { toggle })
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  showReview({ commit }, { user_id: userId, toggle }) {
    return this.$axios
      .post(`/customer/${userId}/show-review`, { toggle })
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  updateEmployeeReviewSettings(
    { commit },
    { user_id: userId, company_id: companyId, settings }
  ) {
    return this.$axios
      .post(`/customer/${userId}/updateEmployeeReviewSettings`, {
        company_id: companyId,
        settings,
      })
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  updateCompanyStatus({ commit }, { userId, status }) {
    return this.$axios
      .post(`/customer/${userId}/set-${status}-status`, { status })
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },
};
