import Vue from 'vue';

export const state = () => ({
  list: [],
  _byId: {},

  activeTree: null,
  activeNode: null,
});

export const getters = {
  activeTree(state) {
    return state._byId[state.activeTree];
  },
  /* mapped active tree */
  mappedNodes(state) {
    const active = state._byId[state.activeTree];

    if (!active) {
      return [];
    }

    const node_list = active.nodes.map((n) => n._id);
    const node_byId = active.nodes.reduce((p, c) => {
      p[c._id] = c;

      return p;
    }, {});

    return node_list.map((n) => {
      const node = node_byId[n];

      if (node.childs.length > 0) {
        node.childs = node.childs.map((c) => {
          if (typeof c === 'object') {
            return node_byId[c._id];
          }

          return node_byId[c];
        });
      }

      return node;
    });
  },

  all(state) {
    return state.list.map((id) => state._byId[id]);
  },

  questionsFromActiveTree(state, getters) {
    return (question_ids) => {
      const tree = getters.activeTree;

      if (!question_ids) {
        return tree.questions;
      }

      return question_ids.map((q_id) =>
        tree.questions.find((q) => q._id == q_id)
      );
    };
  },
};

export const mutations = {
  setAll(state, items) {
    items.forEach((i) => setState(state, i));
  },
  set(state, obj) {
    setState(state, obj);
  },
  delete(state, tree_id) {
    delete state._byId[tree_id];
    const index = state.list.indexOf(tree_id);

    state.list.splice(index, 1);
  },
  setActiveTree(state, tree_id) {
    state.activeTree = tree_id;

    /* let tree = state._byId[tree_id]
    setActiveTreeState(state, tree) */
  },
  setQuestion(state, { tree_id, question }) {
    const node = state._byId[tree_id].nodes.find((n) => {
      return n.questions.some((q) => q._id == question._id);
    });

    const question_index = node.questions.findIndex(
      (q) => q._id == question._id
    );
    Vue.set(node.questions, question_index, question);
  },
  setActiveTreeQuestion(state, { question, new_question }) {
    Object.assign(question, new_question);
  },
};

export const actions = {
  getAll({ commit, rootState }) {
    return this.$axios
      .get('/tree')
      .then((res) => {
        // let trees = res.data.map( t => mapTreeNodeQuestions(t, rootState.questions._byId) )
        commit('setAll', res.data);

        return res.data;
      })
      .catch(console.log);
  },
  get({ commit }, tree_id) {
    return this.$axios
      .get(`/tree/${tree_id}`)
      .then((res) => {
        commit('set', res.data);

        return res.data;
      })
      .catch(console.log);
  },
  save({ commit }, { name, tree_id }) {
    return this.$axios
      .post(`/tree/${tree_id}/save`, { name })
      .then((res) => {
        commit('set', res.data);

        return res.data;
      })
      .catch(console.log);
  },
  create({ commit }, tree) {
    return this.$axios
      .post(`/tree/create`, tree)
      .then((res) => {
        commit('set', res.data);

        return res.data;
      })
      .catch(console.log);
  },
  setActiveTree({ commit }, tree_id) {
    commit('setActiveTree', tree_id);
  },

  addNode({ commit }, { node, tree_id }) {
    return this.$axios
      .post(`/tree/${tree_id}/add-node`, node)
      .then((res) => {
        commit('set', res.data.tree);

        return res.data.node;
      })
      .catch(console.log);
  },

  saveNode({ commit }, { node, tree_id }) {
    return this.$axios
      .post(`/tree/${tree_id}/${node._id}/save`, { node })
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  changeNodeOrder({ commit }, { tree_id, node_id, direction }) {
    return this.$axios
      .post(`/tree/${tree_id}/${node_id}/order/${direction}`)
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },
  deleteNode({ commit }, { tree_id, node_id }) {
    return this.$axios
      .post(`/tree/${tree_id}/${node_id}/delete`)
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  addQuestion({ commit }, { tree_id, node_id, question }) {
    return this.$axios
      .post(`/tree/${tree_id}/${node_id}/add-question`, { question })
      .then((res) => {
        commit('set', res.data.tree);

        return res.data.question;
      });
  },

  deleteQuestion({ commit }, { tree_id, node_id, question_id }) {
    return this.$axios
      .post(`/tree/${tree_id}/${node_id}/delete-question`, { question_id })
      .then((res) => {
        commit('set', res.data.tree);

        return res.data.tree;
      });
  },

  changeQuestionOrder(
    { commit },
    { tree_id, node_id, question_id, direction }
  ) {
    return this.$axios
      .post(`/tree/${tree_id}/${node_id}/${question_id}/${direction}`)
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  addConditionalQuestion(
    { state, commit },
    { question_id, option_id, conditional_question }
  ) {
    const tree_id = state.activeTree;

    if (!tree_id) {
      throw new Error('active tree not defined');
    }

    return this.$axios
      .post(
        `/tree/${tree_id}/${question_id}/${option_id}/add-conditional-question`,
        { conditional_question }
      )
      .then((res) => {
        commit('questions/set', res.data.question, { root: true });
        commit('questions/set', res.data.root_question, { root: true });

        return res.data.question;
      });
  },

  deleteConditionalQuestion(
    { state, commit },
    { option_id, question_id, conditional_question_id }
  ) {
    const tree_id = state.activeTree;

    return this.$axios
      .post(
        `/tree/${tree_id}/${question_id}/${option_id}/delete-conditional-question`,
        { conditional_question_id }
      )
      .then((res) => {
        commit('questions/set', res.data.root_question, { root: true });

        return res.data.root_question;
      });
  },

  saveActiveTreeQuestion({ commit }, { question, new_question }) {
    return this.$axios
      .post(`/question/${question._id}/save`, { question: new_question })
      .then((res) => {
        commit('setActiveTreeQuestion', {
          question,
          new_question: res.data.question,
        });

        return res.data.question;
      });
  },
};

function setState(state, obj) {
  Vue.set(state._byId, obj._id, obj);

  if (!state.list.includes(obj._id)) {
    state.list.push(obj._id);
  }
}

function setActiveTreeState(state, tree) {
  state.activeTree = {
    name: tree.name,
    node_list: [],
    node_byId: {},
  };

  tree.nodes.forEach((n) => {
    Vue.set(state.activeTree.node_byId, n._id, n);

    if (!state.activeTree.node_list.includes(n._id)) {
      state.activeTree.node_list.push(n._id);
    }
  });
}

/*
populates questions in tree.node.questions
 */
function mapTreeNodeQuestions(tree, q_byId) {
  tree.nodes.map((n) => {
    n.questions = n.questions.map((q) => {
      return q_byId[q];
    });

    return n;
  });

  return tree;
}
