import Vue from 'vue';
import { Roles, hrRoles, salesRoles, hrAndSales } from '../lib/roles';
import { normalizeDate } from '../lib/dates';

export const state = () => ({
  list: [],
  _byId: {},
  active: null,
  advisors: [],
  sales: [],
  current: {},
  users: [],
  admins: [],
  partners: [],
  bambeeStaff: [],
});

export const getters = {
  active(state) {
    return state._byId[state.activeTree];
  },

  all(state) {
    return state.list.map((id) => state._byId[id]);
  },

  byId(state) {
    return function (id) {
      return state._byId[id];
    };
  },

  advisors(state) {
    return state.advisors;
  },

  accountExecutives(state) {
    return state.sales.filter((u) => u.role == Roles.SALES);
  },

  sdrs(state) {
    return state.sales.filter((u) => u.role == Roles.SDR);
  },

  insuranceAgents(state) {
    return state.sales.filter((u) => u.role == Roles.INSURANCE);
  },

  getAdvisorByCompany(state) {
    return state.current;
  },

  bambeeStaff(state) {
    return state.bambeeStaff;
  },

  getBambeeStaffWithoutDefault(state) {
    return state.bambeeStaff.filter((staff) => staff.role !== 'default');
  },

  getHrAndAccountExecutiveRoles() {
    return [...hrAndSales, Roles.ADMIN];
  },

  hrAndAccountExecutive(state, getters) {
    // No default role
    return getters.getBambeeStaffWithoutDefault.filter((staff) =>
      getters.getHrAndAccountExecutiveRoles.includes(staff.role)
    );
  },

  getAdmins(state) {
    return state.admins;
  },

  newLeadSalesSelection(state) {
    return state.bambeeStaff.filter((staff) => {
      return (
        staff.role === Roles.SALES ||
        staff.role === Roles.SDR ||
        staff.role === Roles.ADMIN ||
        staff.role === Roles.HR_ADMIN
      );
    });
  },
};

export const mutations = {
  setAll(state, items) {
    items.forEach((i) => setState(state, i));
    state.all = items;
  },

  set(state, obj) {
    setState(state, obj);
  },

  setActive(state, id) {
    state.active = id;
  },

  setCompany(state, { user_id, company }) {
    const user = state._byId[user_id];
    user._company = company;
  },

  setAdvisors(state, advisors) {
    state.advisors = advisors;
  },

  setAdvisor(state, advisor) {
    state.current = advisor;
  },

  setSales(state, sales) {
    state.sales = sales;
  },

  setBambee(state, items) {
    const staff = items.reduce(
      (res, user) => {
        if (hrRoles.includes(user.role)) {
          res.advisors.push(user);
        }

        if (salesRoles.includes(user.role)) {
          res.sales.push(user);
        }

        if (user.role === Roles.PARTNER) {
          res.partners.push(user);
        }

        if (user.role === Roles.ADMIN) {
          res.admin.push(user);
        }

        return res;
      },
      {
        admin: [],
        sales: [],
        advisors: [],
        partners: [],
      }
    );

    state.advisors = staff.advisors;
    state.sales = staff.sales;
    state.partners = staff.partners;
    state.admins = staff.admins;
  },

  setBambeeSorted(state, items) {
    state.bambeeStaff = items;
  },

  setClientEmployees(state, items) {
    state.users = items.users.concat(items.employees);
  },

  setAdmins(state, admins) {
    state.admins = admins;
  },
};

export const actions = {
  create({ commit }, { user, company, assignedCompanyIDs }) {
    return this.$axios
      .post('/user/create', { user, company, assignedCompanyIDs })
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  updateEmail({ commit }, { userId, email }) {
    return this.$axios
      .post(`/user/${userId}/update-email`, { email })
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  update({ commit }, user) {
    if (user.profile) {
      user.profile.dob = normalizeDate(user.profile.dob);
      user.profile.start_date = normalizeDate(user.profile.start_date);
    }

    return this.$axios
      .post(`/user/${user._id}/update`, { user })
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  updatePassword({ commit }, { user, new_password }) {
    return this.$axios
      .post(`/user/${user._id}/update-password`, { new_password })
      .then((res) => {
        commit('set', res.data);

        return res.data;
      });
  },

  createCompany({ commit }, company) {
    return this.$axios
      .post(`/user/${company._owner}/register-company`, { company })
      .then((res) => {
        return res.data;
      });
  },

  getAll({ commit }) {
    return this.$axios.get('/user').then((res) => {
      commit('setAll', res.data);

      return res.data;
    });
  },

  async get({ commit }, id) {
    return await this.$axios.get(`/user/${id}`).then((res) => {
      commit('set', res.data);

      return res.data;
    });
  },

  getAdvisors({ commit }) {
    return this.$axios.get('/user/getAdvisors').then((res) => {
      commit('setAdvisors', res.data);

      return res.data;
    });
  },

  getAdvisorByCompanyId({ commit, state }, { companyId }) {
    return this.$axios
      .get(`/user/getAdvisorByCompanyId/${companyId}`)
      .then((res) => {
        commit('setAdvisor', res.data);

        return res.data;
      });
  },

  getSales({ commit }) {
    return this.$axios.get('/user/getSales').then((res) => {
      commit('setSales', res.data);

      return res.data;
    });
  },

  getAdmins({ commit }) {
    return this.$axios.get('/user/getAdmins').then((res) => {
      commit('setAdmins', res.data);

      return res.data;
    });
  },

  setActive({ commit }, id) {
    commit('setActive', id);
  },

  addNote({ commit }, { user, text }) {
    const company_id = user._company._id;

    return this.$axios
      .post(`/company/${company_id}/add-note`, { text })
      .then((res) => {
        commit('setCompany', { user_id: user._id, company: res.data });

        return res.data;
      });
  },

  getBambeeStaff({ commit }) {
    return this.$axios.get('/user/getBambee').then((res) => {
      commit('setBambee', res.data);
      commit('setBambeeSorted', res.data);

      return res.data;
    });
  },

  getClientEmployees({ commit }) {
    return this.$axios.get('/user/getClientEmployees').then((res) => {
      commit('setClientEmployees', res.data);

      return res.data;
    });
  },
};

function setState(state, obj) {
  Vue.set(state._byId, obj._id, obj);

  if (!state.list.includes(obj._id)) {
    state.list.push(obj._id);
  }
}
