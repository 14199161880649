import { getCookie } from '@/lib/cookie-helpers';
import { setContext } from '@apollo/client/link/context';

export default function (tokenName) {
  return setContext(async (_, { headers }) => {
    const token = await getCookie(tokenName);

    return {
      authorization: `Bearer ${token}` || '', // for Subscription
      headers: {
        ...headers,
        authorization: `Bearer ${token}` || '',
      },
    };
  });
}
