import Vue from 'vue';

function setPip(state, pip) {
  const index = state.pips.findIndex((pip_id) => pip_id == pip._id);

  if (index == -1) {
    state.pips.push(pip._id);
  }

  Vue.set(state._byId, pip._id, pip);
}

export const state = () => ({
  pips: [],
  my_pips: [],
  _byId: {},
  termination: {},
});

export const mutations = {
  setPip,
  setPips(state, pips) {
    pips.forEach((p) => setPip(state, p));
  },
  deletePip(state, pip) {
    const index = state.pips.findIndex((pip_id) => pip_id == pip._id);

    if (index != -1) {
      state.pips.splice(index, 1);
    }

    Vue.set(state._byId, pip._id, pip);
  },

  setTermination(state, termination) {
    state.termination = termination;
  },
};

export const getters = {
  pip(state) {
    return function (id) {
      return state._byId[id];
    };
  },
  allPip(state) {
    return state.pips.map((id) => state._byId[id]);
  },
};

export const actions = {
  getPip({ commit }, { type, cap_id }) {
    return this.$axios.get(`/cap/${type}/${cap_id}`).then((res) => {
      commit('setPip', res.data);
    });
  },
  addNote({ commit }, { type, pip }) {
    this.$axios.post(`/cap/${type}/${pip._id}/addNote`, pip).then((res) => {
      commit('setPip', res.data);
    });
  },
  deleteNote({ commit }, { type, pip }) {
    this.$axios
      .post(`/cap/${type}/${pip.pip._id}/deleteNote`, {
        pip: pip.pip,
        note_id: pip.note_id,
      })
      .then((res) => {
        commit('setPip', res.data);
      });
  },
  denyPip({ commit }, { type, pip }) {
    return this.$axios.post(`/cap/${type}/${pip._id}/deny`).then((res) => {
      commit('setPip', res.data);

      return res.data;
    });
  },
  approvePip({ commit }, { type, pip }) {
    return this.$axios.post(`/cap/${type}/${pip._id}/approve`).then((res) => {
      commit('setPip', res.data);

      return res.data;
    });
  },
  toDraft({ commit }, { type, pip }) {
    return this.$axios.post(`/cap/${type}/${pip._id}/to-draft`).then((res) => {
      commit('setPip', res.data);

      return res.data;
    });
  },
};
