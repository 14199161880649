import billingApolloClient from '@/modules/Billing/gql/billing.apollo';
import companyApolloClient from '@/modules/CompanyService/lib/company.apollo';
import employeeApolloClient from '@/modules/EmployeeVoices/services/employee.apollo';
import payrollApolloClient from '@/modules/payroll/lib/payroll.apollo';
import taskApolloClient from '@/modules/task/libs/task.apollo';
import trainingApolloClient from '@/modules/training/lib/training.apollo';
import { provideApolloClient } from '@vue/apollo-composable';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';

// eslint-disable-next-line import/prefer-default-export
export const Client = {
  BILLING: 'billing',
  PAYROLL: 'payroll',
  COMPANY: 'company',
  TRAINING: 'training',
  TASK: 'task',
  EMPLOYEE: 'employee',
} as const;

export const CachePolicy = {
  CACHE_FIRST: 'cache-first',
  CACHE_ONLY: 'cache-only',
  CACHE_AND_NETWORK: 'cache-and-network',
  NETWORK_ONLY: 'network-only',
  NO_CACHE: 'no-cache',
  STANDBY: 'standby',
} as const;

export const BillingClient = { clientId: Client.BILLING };
export const PayrollClient = { clientId: Client.PAYROLL };
export const CompanyClient = { clientId: Client.COMPANY };
export const TrainingClient = { clientId: Client.TRAINING };
export const TaskClient = { clientId: Client.TASK };
export const EmployeeClient = { clientId: Client.EMPLOYEE };

export const providePayrollClient = () =>
  provideApolloClient(payrollApolloClient);
export const provideBillingClient = () =>
  provideApolloClient(billingApolloClient);
export const provideTaskClient = () => provideApolloClient(taskApolloClient);
export const provideTrainingClient = () =>
  provideApolloClient(trainingApolloClient);
export const provideCompanyClient = () =>
  provideApolloClient(companyApolloClient);
export const provideEmployeeClient = () =>
  provideApolloClient(employeeApolloClient);

// Add all new clients to this map
// This is used to help the apollo wrapper correctly set the client at the time of usage.
export const clientMap = {
  [Client.BILLING]: {
    client: billingApolloClient,
    provide: () => provideBillingClient(),
  },
  [Client.PAYROLL]: {
    client: payrollApolloClient,
    provide: () => providePayrollClient(),
  },
  [Client.COMPANY]: {
    client: companyApolloClient,
    provide: () => provideCompanyClient(),
  },
  [Client.TRAINING]: {
    client: trainingApolloClient,
    provide: () => provideTrainingClient(),
  },
  [Client.TASK]: {
    client: taskApolloClient,
    provide: () => provideTaskClient(),
  },
  [Client.EMPLOYEE]: {
    client: employeeApolloClient,
    provide: () => provideEmployeeClient(),
  },
};
