import sortBy from 'lodash/sortBy';

export const insuranceLines = {
  'workers-compensation-insurance': {
    id: 'workers-compensation-insurance',
    name: 'Workers Compensation',
    shortName: 'WC',
    description:
      "Workers Compensation covers an employee's lost wages and the cost of resulting medical treatment for a work-related injury or disease, as well as services needed to help the employee recover. WC includes coverage for Employer’s Liability.",
    featuredOrder: 1,
  },
  'employment-practices-liability-insurance': {
    id: 'employment-practices-liability-insurance',
    name: 'Employment Practices Liability',
    shortName: 'EPLI',
    description:
      'EPLI protects your business in the case of a variety of employment-related issues, such as wrongful termination, discrimination, and sexual harassment.',
    featuredOrder: 2,
  },
  'general-liability-insurance': {
    id: 'general-liability-insurance',
    name: 'General Liability',
    shortName: 'General',
    description:
      'General Liability is a foundational business insurance policy. It protects your business from claims of third party injury or property damage, a tenant’s legal liability to their landlord, products liability, advertising injury and more.',
    featuredOrder: 3,
  },
  'professional-liability-insurance': {
    id: 'professional-liability-insurance',
    name: 'Professional Liability',
    shortName: 'Prof',
    description:
      "Synonymous with Errors & Omissions, this coverage protects you against claims of negligence and financial loss arising from mistaken services. It's carried by businesses who provide professional services, such as lawyers, architects, and many others.",
    featuredOrder: 4,
  },
  'cyber-insurance': {
    id: 'cyber-insurance',
    name: 'Cyber',
    shortName: 'Cyber',
    description:
      'Cyber insurance protects your business from claims that important information has been released to the public or that incendiary material was published, and also pays for extortion costs, lost business data, or lost income due to digital disruption.',
    featuredOrder: 5,
  },
  'pollution-liability-insurance': {
    id: 'pollution-liability-insurance',
    name: 'Pollution Liability',
  },
  'environmental-liability-insurance': {
    id: 'environmental-liability-insurance',
    name: 'Environmental Liability',
  },
  'product-liability': {
    id: 'product-liability',
    name: 'Product Liability',
  },
  'business-owners-policy': {
    id: 'business-owners-policy',
    name: 'Business Owners',
  },
  'commercial-property': {
    id: 'commercial-property',
    name: 'Commercial Property',
  },
  'commercial-auto': {
    id: 'commercial-auto',
    name: 'Commercial Auto',
  },
  'directors-and-officers-insurance': {
    id: 'directors-and-officers-insurance',
    name: 'Directors & Officers',
  },
  'liquor-liability': {
    id: 'liquor-liability',
    name: 'Liquor Liability',
  },
  'special-events': {
    id: 'special-events',
    name: 'Special Events',
  },
  'inland-marine': {
    id: 'inland-marine',
    name: 'Inland Marine',
  },
  'ocean-marine': {
    id: 'ocean-marine',
    name: 'Ocean Marine',
  },
  'stock-through-put': {
    id: 'stock-through-put',
    name: 'Stock Through Put',
  },
  'medical-malpractice': {
    id: 'medical-malpractice',
    name: 'Medical Malpractice',
  },
  'equipment-breakdown': {
    id: 'equipment-breakdown',
    name: 'Equipment Breakdown',
  },
  'business-interruption': {
    id: 'business-interruption',
    name: 'Business Interruption',
  },
  'garage-liability': {
    id: 'garage-liability',
    name: 'Garage Liability',
  },
  'dealers-open-lot': {
    id: 'dealers-open-lot',
    name: 'Dealers Open Lot',
  },
  'hired-and-non-owned-auto': {
    id: 'hired-and-non-owned-auto',
    name: 'Hired and Non Owned Auto',
  },
  'fiduciary-bond': {
    id: 'fiduciary-bond',
    name: 'Fiduciary Bond',
  },
  'fidelity-bond': {
    id: 'fidelity-bond',
    name: 'Fidelity Bond',
  },
  'surety-bond': {
    id: 'surety-bond',
    name: 'Surety Bond',
  },
  'excess-property': {
    id: 'excess-property',
    name: 'Excess Property',
  },
  'excess-casualty': {
    id: 'excess-casualty',
    name: 'Excess Casualty',
  },
  umbrella: {
    id: 'umbrella',
    name: 'Umbrella',
  },
  'difference-in-conditions': {
    id: 'difference-in-conditions',
    name: 'Difference In Conditions',
  },
  'personal-lines': {
    id: 'personal-lines',
    name: 'Personal Lines',
  },
  'program-administrator': {
    id: 'program-administrator',
    name: 'Program Administrator',
  },
};
export const featuredInsuranceLineIds = Object.values(insuranceLines)
  .filter((line) => line.featuredOrder)
  .map((line) => line.id);

export const selfInsuranceLineMapping = {
  'workers-compensation-insurance': 'workers',
  'employment-practices-liability-insurance': 'epli',
  'general-liability-insurance': 'general',
  'professional-liability-insurance': 'pro_liab',
  'cyber-insurance': 'cyber',
};

export const insurancePlanTypes = [
  {
    key: 'primary',
    name: 'Primary Insurance',
  },
  {
    key: 'excess',
    name: 'Excess Insurance',
  },
  {
    key: 'tail',
    name: 'Tail Insurance',
  },
  {
    key: 'umbrella',
    name: 'Umbrella Insurance',
  },
];

export const bambeeInsuranceStatuses = [
  {
    key: 'none',
    name: 'Not a Bambee Plan',
  },
  {
    key: 'is-sold-by-bambee',
    name: 'Sold by Bambee',
  },
  {
    key: 'import-in-progress',
    name: 'BOR to Bambee in progress',
  },
  {
    key: 'is-broker-of-record',
    name: 'BOR to Bambee completed',
  },
];

export const companyInsurancePlanStatuses = [
  {
    key: 'unknown',
    name: 'Unknown',
  },
  {
    key: 'draft',
    name: 'Draft',
  },
  {
    key: 'current',
    name: 'Current',
  },
  {
    key: 'cancelled',
    name: 'Cancelled',
  },
  {
    key: 'reinstated',
    name: 'Reinstated',
  },
  {
    key: 'pending-cancellation',
    name: 'Pending Cancellation',
  },
];

export const insuranceNeeds = [
  {
    name: 'General Inquiry',
    value: 'general_inquiry',
  },
  {
    name: 'EPLI',
    value: 'epli',
  },
  {
    name: 'Director and Officer',
    value: 'dno',
  },
  {
    name: 'General Liability',
    value: 'gl',
  },
  {
    name: 'Cyber Liability',
    value: 'cyber',
  },
  {
    name: 'Workers Comp',
    value: 'wc',
  },
  {
    name: 'Commercial Crime',
    value: 'cc',
  },
  {
    name: 'Excess Liability',
    value: 'xs',
  },
  {
    name: 'Umbrella Liability',
    value: 'umb',
  },
];

export const bambeeAgentData = {
  name: 'Bambee Insurance Services',
  address: {
    address1: "6980 O'Bannon Dr",
    address3: '',
    city: 'Las Vegas',
    state: 'NV',
    zip: '89117',
  },
  phoneNumber: '+1 913 645 2171',
  email: 'jacob.wilcox@bambeeinsuranceservices.com',
};
