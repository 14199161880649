import Vue from 'vue';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';

export default () => {
  const mixins = {
    computed: {
      current_user() {
        return this.$store.state.current_user;
      },

      cleavePhoneFormat() {
        return {
          delimiters: ['+1', ' ', '(', ')', ' ', '-'],
          blocks: [0, 0, 0, 3, 0, 3, 4],
          numericOnly: true,
        };
      },

      cleaveFEINFormat() {
        return {
          delimiters: ['-'],
          blocks: [2, 7],
          numericOnly: true,
        };
      },

      cleaveCurrencyFormat() {
        return {
          prefix: '$ ',
          blocks: [0],
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        };
      },

      cleaveZipCode() {
        return {
          blocks: [5],
          numericOnly: true,
        };
      },

      cleaveYearFormat() {
        return {
          blocks: [4],
          numericOnly: true,
        };
      },
    },
    methods: {
      formatDate(date, format) {
        return dayjs(date).format(format);
      },

      roleIn(roles) {
        if (!this.current_user) {
          return false;
        }

        return roles.includes(this.current_user.role);
      },

      cap_type(model) {
        switch (model) {
          case 'Pip':
            return 'pip';
          case 'Writtenwarning':
            return 'written-warning';
          case 'Verbalwarning':
            return 'verbal-warning';
          case 'Incident':
            return 'incident';
        }
      },

      employeeActionStatus(user) {
        function toTitleCase(str) {
          return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          });
        }

        const states = Object.keys(user.states);

        // type = 'is-danger', 'is-success', etc
        const status = {
          type: '',
          message: '',
        };

        states.forEach((k) => {
          switch (k) {
            case '_resignation':
              if (!user.states[k]) {
                return status;
              }

              if (user.states[k].status == 'started') {
                status.type = 'is-warning';
                status.message = 'Action Required';
              }

              if (
                [
                  'awaiting approval',
                  'awaiting signature',
                  'awaiting acknowledgement',
                ].includes(user.states[k].status)
              ) {
                status.type = 'is-success';
                status.message = toTitleCase(user.states[k].status);
              }

              break;
            case 'self_signup':
              if (user.states[k].registered && !user.states[k].activated) {
                status.type = 'is-warning';
                status.message = 'Action Required';
              }
              break;
          }
        });

        return status;
      },

      downloadExcel(data, fileName) {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, fileName);
        XLSX.writeFile(wb, fileName);
      },
    },
  };

  Vue.mixin(mixins);
};
