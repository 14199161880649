import keyBy from 'lodash/keyBy';
import Vue from 'vue';

const formatCreatePolicyData = (policy) => ({
  title: policy.title,
  description: policy.description,
  icon: policy.icon,
  isContractor: policy.isContractor,
  hrRequestId: policy.hrRequestId,
  salesforceCaseId: policy.salesforceCaseId,
  message: policy.message,
  bundles: policy.bundles,
});

export const state = () => ({
  // Policies
  policies: {},
  policiesByCompany: {},

  // Bundles
  bundles: {},
  bundlesForCompany: {},
});

export const mutations = {
  /**
   * Policies
   */
  setPolicy(state, policy) {
    state.policies[policy._id] = policy;
  },
  setPolicyPartial(state, { policyId, partial }) {
    if (!(policyId in state.policies)) {
      return;
    }

    Object.entries(partial).forEach(([key, value]) => {
      Vue.set(state.policies[policyId], key, value);
    });
  },

  setPoliciesForCompany(state, { policies, companyId }) {
    const policiesObj = keyBy(policies, '_id');
    const companyPolicies = Object.keys(policiesObj);

    state.policies = {
      ...state.policies,
      ...policiesObj,
    };
    Vue.set(state.policiesByCompany, companyId, companyPolicies);
  },

  /**
   * Bundles
   */
  saveBulkCompanyBundles(state, { companyId, bundles }) {
    const bundlesObj = keyBy(bundles, 'id');
    const companyBundles = Object.keys(bundlesObj);

    state.bundles = {
      ...state.bundles,
      ...bundlesObj,
    };
    Vue.set(state.bundlesForCompany, companyId, companyBundles);
  },
  createCompanyBundle(state, { bundle, companyId }) {
    const newCompanyBundles = [
      ...state.bundlesForCompany[companyId],
      bundle.id,
    ];

    Vue.set(state.bundles, bundle.id, bundle);
    Vue.set(state.bundlesForCompany, companyId, newCompanyBundles);
  },
  editCompanyBundle(state, { bundle }) {
    Vue.set(state.bundles, bundle.id, bundle);
  },
  deleteCompanyBundle(state, { companyId, bundleId }) {
    const newCompanyBundles = state.bundlesForCompany[companyId].filter(
      (bundle) => bundle !== bundleId
    );

    Vue.set(state.bundlesForCompany, companyId, newCompanyBundles);
    Vue.delete(state.bundles, bundleId);
  },
  publishCompanyBundle(state, { bundleId, value }) {
    const bundle = state.bundles[bundleId];
    bundle.publishedAt = value ? Date.now() : null;

    Vue.set(state.bundles, bundle.id, bundle);
  },

  updatePolicyName(state, { policyId, name }) {
    const currentPolicy = state.policies[policyId];

    Vue.set(currentPolicy, 'name', name);
  },
};

export const getters = {
  /**
   * Policies
   */
  // Generic
  getPolicy: (state) => (id) => state.policies[id] || null,
  getPoliciesByCompanyId: (state) => (companyId) =>
    (state.policiesByCompany[companyId] || []).map((id) => state.policies[id]),

  // Company in scope
  getPoliciesInScope: (state, getters, rootState, rootGetters) =>
    getters.getPoliciesByCompanyId(rootGetters.companyId),

  /**
   * Bundles
   */
  // Generic
  getBundleById: (state) => (bundleId) => state.bundles[bundleId] || {},
  getBundlesByCompanyId: (state) => (companyId) =>
    (state.bundlesForCompany[companyId] || []).map((id) => state.bundles[id]),

  // Company in scope
  getBundlesInScope: (state, getters, rootState, rootGetters) =>
    getters.getBundlesByCompanyId(rootGetters.companyId),
};

export const actions = {
  /**
   * Policies
   */
  fetchPolicyById({ commit }, id) {
    return this.$axios.get(`/policy/${id}`).then((res) => {
      commit('setPolicy', res.data);

      return res.data;
    });
  },
  async createBambeePolicyFromTemplate(_, { companyId, templateId, policy }) {
    const { data } = await this.$axios.post(
      `/companies/v1/company/${companyId}/policies/bambee/new-from-template`,
      {
        ...formatCreatePolicyData(policy),
        templateId,
      },
      { baseURL: `${process.env.API_URL}` }
    );

    return data?.policyId;
  },

  async createBambeePolicyFromBlank(_, { companyId, policy }) {
    const { data } = await this.$axios.post(
      `/companies/v1/company/${companyId}/policies/bambee/new-from-blank`,
      {
        ...formatCreatePolicyData(policy),
      },
      { baseURL: `${process.env.API_URL}` }
    );

    return data?.policyId;
  },
  async createPandaPolicyFromTemplate(
    _,
    { companyId, pandaTemplateId, policy }
  ) {
    const { data } = await this.$axios.post(
      `/companies/v1/company/${companyId}/policies/panda/new-from-global-template`,
      {
        ...formatCreatePolicyData(policy),
        globalTemplateId: pandaTemplateId,
      },
      { baseURL: `${process.env.API_URL}` }
    );

    return data?.policyId;
  },
  async createPandaPolicyFromFile(_, { companyId, fileUrl, policy }) {
    const { data } = await this.$axios.post(
      `/companies/v1/company/${companyId}/policies/panda/new-from-pdf`,
      {
        ...formatCreatePolicyData(policy),
        url: fileUrl,
      },
      { baseURL: `${process.env.API_URL}` }
    );

    return data?.policyId;
  },

  updatePolicy({ commit }, { policyId, policy }) {
    return this.$axios
      .put(`/documents/v1/policy/${policyId}/`, policy, {
        baseURL: process.env.API_URL,
      })
      .then(() => {
        commit('updatePolicyName', {
          policyId,
          name: policy.name,
        });
      });
  },
  passPolicyQA({ commit }, { eventId }) {
    return this.$axios.post(`/policy/${eventId}/qa-pass`).then((res) => {
      commit('setPolicy', res.data);
    });
  },
  sendForApproval({ commit }, { policyId }) {
    return this.$axios
      .post(
        `/documents/v1/policy/${policyId}/lifecycle/send-for-approval`,
        null,
        { baseURL: process.env.API_URL }
      )
      .then(() => {
        commit('setPolicyPartial', {
          policyId,
          partial: {
            status: 'sent',
          },
        });
      });
  },
  createTemplateFromDocument({ commit }, { policyId }) {
    return this.$axios
      .post(
        `/documents/v1/policy/${policyId}/lifecycle/create-template-from-document`,
        null,
        { baseURL: process.env.API_URL }
      )
      .then(() => {
        commit('setPolicyPartial', {
          policyId,
          partial: {
            status: 'qa',
          },
        });
      });
  },
  implement({ commit }, { policyId, employees }) {
    return this.$axios
      .post(
        `/documents/v1/policy/${policyId}/lifecycle/implement`,
        { employees },
        { baseURL: process.env.API_URL }
      )
      .then((res) => {
        commit('setPolicy', res.data);
      });
  },
  cancel({ commit }, { policyId }) {
    return this.$axios
      .post(`/documents/v1/policy/${policyId}/lifecycle/cancel`, null, {
        baseURL: process.env.API_URL,
      })
      .then((res) => {
        commit('setPolicy', res.data);
      });
  },
  async forceApprove({ commit }, { policyId }) {
    const { data } = await this.$axios.post(
      `/documents/v1/policy/${policyId}/lifecycle/force-approve`,
      null,
      {
        baseURL: process.env.API_URL,
      }
    );
    commit('setPolicy', data);
  },
  fetchPoliciesForCompany({ commit, rootGetters: { companyId } }) {
    return this.$axios.get(`/policy/list-company/${companyId}`).then((res) => {
      commit('setPoliciesForCompany', {
        companyId,
        policies: res.data,
      });
    });
  },
  fetchMissingPolicies(_, { companyId, isContractor }) {
    return this.$axios.get(
      `/companies/v1/company/${companyId}/policies/missing-policies?isContractor=${isContractor}`,
      { baseURL: `${process.env.API_URL}` }
    );
  },
  bulkCreatePoliciesForCompany(
    { commit, getters, rootGetters: { companyId } },
    { templateIDs }
  ) {
    return this.$axios
      .post(
        `/companies/v1/company/${companyId}/policies/from-global-templates`,
        { templateIDs },
        { baseURL: process.env.API_URL }
      )
      .then((res) => {
        const policies = [
          ...getters.getPoliciesByCompanyId(companyId),
          ...res.data.policies,
        ];
        commit('setPoliciesForCompany', {
          companyId,
          policies,
        });

        return {
          policiesCount: res.data.policies.length,
          errors: res.data.errors,
        };
      });
  },
  async getImplementedEmployees(_, { policyId }) {
    const { data } = await this.$axios.get(`/policy/${policyId}`);

    const feeds = data._feeds;
    const documents = data._documents;

    const signedIds = documents.filter((d) => d.signed).map((d) => d._employee);
    const employees = feeds.filter((f) => f.active).map((f) => f._employee);

    return employees.concat(signedIds);
  },
  sendReminder(_, { policyId, employeeIds }) {
    return this.$axios.post(`/policy/${policyId}/send-reminder`, {
      employeeIds,
    });
  },

  /**
   * Policy Bundles
   */
  fetchCompanyPolicyBundles({ commit, rootGetters: { companyId } }) {
    return this.$axios
      .get(`/companies/v1/company/${companyId}/policies/bundles`, {
        baseURL: process.env.API_URL,
      })
      .then((res) => {
        commit('saveBulkCompanyBundles', {
          companyId,
          bundles: res.data.policyBundles,
        });
      });
  },
  createBundle({ commit, rootGetters: { companyId } }, payload) {
    return this.$axios
      .post(`/companies/v1/company/${companyId}/policies/bundles`, payload, {
        baseURL: process.env.API_URL,
      })
      .then((res) => {
        commit('createCompanyBundle', {
          bundle: res.data,
          companyId,
        });
      });
  },
  deleteBundle({ commit, rootGetters: { companyId } }, bundleId) {
    return this.$axios
      .delete(
        `/companies/v1/company/${companyId}/policies/bundles/bundle/${bundleId}`,
        { baseURL: process.env.API_URL }
      )
      .then(() => {
        commit('deleteCompanyBundle', {
          bundleId,
          companyId,
        });
      });
  },
  editBundle({ commit, rootGetters: { companyId } }, payload) {
    return this.$axios
      .patch(
        `/companies/v1/company/${companyId}/policies/bundles/bundle/${payload.bundleId}`,
        payload,
        { baseURL: process.env.API_URL }
      )
      .then((res) => {
        commit('editCompanyBundle', { bundle: res.data });
      });
  },
  publishBundle({ commit, rootGetters: { companyId } }, { bundleId, value }) {
    return this.$axios
      .patch(
        `/companies/v1/company/${companyId}/policies/bundles/bundle/${bundleId}/publish`,
        { value },
        { baseURL: process.env.API_URL }
      )
      .then(() => {
        commit('publishCompanyBundle', {
          bundleId,
          value,
        });
      });
  },

  /**
   * Policy Documents
   */
  retractSignedDocuments(_, { policyId, documentIds, reason }) {
    return this.$axios.post(
      `/documents/v1/policy/${policyId}/lifecycle/retract-signed`,
      {
        ids: documentIds,
        reason,
      },
      {
        baseURL: process.env.API_URL,
      }
    );
  },
  retractUnsignedDocuments(_, { policyId, employeeIds }) {
    return this.$axios.post(
      `/documents/v1/policy/${policyId}/lifecycle/retract`,
      {
        employeeIds,
      },
      {
        baseURL: process.env.API_URL,
      }
    );
  },
};
