const reasonGroupsToPlainList = (reasonGroups, reasonFilter) => {
  const items = [];
  reasonGroups.forEach((group) => {
    const reasons = reasonFilter
      ? group.reasons.filter(reasonFilter)
      : group.reasons;

    if (reasons.length) {
      const groupLabel = group.internalText || group.externalText || group.code;
      items.push({
        divider: true,
        header: groupLabel.toUpperCase(),
      });
      reasons.forEach((reason) => {
        items.push({
          code: reason.code,
          groupCode: group.code,
          groupLabel,
          label: reason.internalText || reason.externalText || reason.code,
        });
      });
    }
  });

  return items;
};

export default reasonGroupsToPlainList;
