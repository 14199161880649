const teamRoleNames = Object.freeze({
  ac: 'Account Coordinator',
  ae: 'Account Executive',
  hbs: 'Handbook Specialist',
  hrm: 'HR Manager',
  obs: 'HR Onboarding Specialist',
  oc: 'Onboarding Coordinator',
  hrc: 'HR Coordinator',
  bm: 'Business Manager',
  pym: 'Payroll Manager',
});

type rk = keyof typeof teamRoleNames;
type TeamRole = { [k in rk]: k };

export const TEAM_ROLE: Readonly<TeamRole> = Object.keys(teamRoleNames).reduce(
  (a, k) => ({
    ...a,
    [k]: k,
  }),
  {} as TeamRole
);
export default teamRoleNames;
