import reasonGroupsToPlainList from '../lib/cancellationHelper';
import notesNeedsGroup from '../lib/notesNeedsGroups';

export const state = () => ({
  notes: [],
});

export const mutations = {
  setNotes(state, notes) {
    state.notes = notes;
  },
};

export const actions = {
  getNotesNeedsList({ commit }) {
    const list = reasonGroupsToPlainList(notesNeedsGroup, (n) => n.active);
    commit('setNotes', list);

    return list;
  },
};
