/* eslint-disable @typescript-eslint/no-shadow  */
import qs from 'qs';
import Vue from 'vue';

export const state = () => ({
  companyInScope: '',
  companies: {},
  companiesBasicInfo: {}, // TODO: merge with companies with /customer drop
  companiesStats: {},
  usersForCompany: {},
  onboardingMilestones: {},
});

export const getters = {
  // Generic getters
  byId: (state) => (companyId) => state.companies[companyId],
  basicInfoById: (state) => (companyId) => state.companiesBasicInfo[companyId],
  statsById: (state) => (companyId) => state.companiesStats[companyId],
  getUsersForCompany: (state) => (companyId) =>
    state.usersForCompany[companyId] || [],

  // Getters for company in scope
  inScope: (state, getters) => getters.byId(state.companyInScope) || {},
  basicInfoInScope: (state, getters) =>
    getters.basicInfoById(state.companyInScope) || {},
  statsInScope: (state, getters) =>
    getters.statsById(state.companyInScope) || {},
  onboardingMilestones: (state) => (companyId) => {
    return state.onboardingMilestones[companyId] || [];
  },
  onboardingMilestonesByTag:
    (state, getters) =>
    ({ companyId, tag }) =>
      getters
        .onboardingMilestones(companyId)
        .filter((milestone) => milestone.tags?.includes(tag)),
};

export const actions = {
  /**
   * Company
   */
  fetchCompanyBasicInfo({ dispatch, state }, { companyId }) {
    if (companyId in state.companiesBasicInfo) {
      return Promise.resolve();
    }

    return dispatch('getCompanyBasicInfo', companyId);
  },
  getCompanyBasicInfo({ commit }, companyId) {
    return this.$axios
      .get(`/companies/v1/company/${companyId}/basic-info`, {
        baseURL: `${process.env.API_URL}`,
      })
      .then((res) => {
        commit('setCompanyBasicInfo', {
          companyId,
          info: res.data,
        });

        return res.data;
      });
  },
  getStats({ commit, state }, companyId) {
    const realCompanyId = companyId || state.companyInScope;

    return this.$axios
      .get(`/companies/v1/company/${realCompanyId}/stats`, {
        baseURL: `${process.env.API_URL}`,
      })
      .then((res) => {
        commit('setCompanyStats', {
          companyId: realCompanyId,
          stats: res.data,
        });

        return res.data;
      });
  },
  getCompanyUsers({ commit }, { companyId }) {
    return this.$axios
      .get(`/user/getUsersByCompanyId/${companyId}`)
      .then((res) => {
        commit('setCompanyUsers', {
          companyId,
          users: res.data,
        });
      });
  },
  async createSecondaryCompany(_, { companyId, newCompanyData }) {
    const { data } = await this.$axios.post(
      `/companies/v1/company/${companyId}/secondary`,
      {
        ...newCompanyData,
      },
      {
        baseURL: `${process.env.API_URL}`,
      }
    );

    return data;
  },
  async trackCallDisposition(
    { commit },
    { companyId, customerId, callId, disposition }
  ) {
    await this.$axios.patch(
      `/companies/v1/company/${companyId}/calls/${callId}`,
      {
        customerId,
        disposition,
      },
      {
        baseURL: `${process.env.API_URL}`,
      }
    );
    commit('trackCallDisposition', {
      companyId,
      callId,
      disposition,
    });
  },
  toggleSetting({ commit }, { companyId, key, value }) {
    return this.$axios
      .patch(
        `/companies/v1/company/${companyId}/settings`,
        {
          key,
          value,
        },
        {
          baseURL: `${process.env.API_URL}`,
        }
      )
      .then(() => {
        commit('updateGenericSetting', {
          companyId,
          key,
          value,
        });
      });
  },
  toggleBci({ commit }, { companyId, value }) {
    return this.$axios
      .put(
        `/companies/v1/company/${companyId}/settings/bci`,
        {
          value,
        },
        {
          baseURL: `${process.env.API_URL}`,
        }
      )
      .then(() => {
        commit('updateBciSetting', {
          companyId,
          value,
        });
      });
  },
  toggleInsurance({ commit }, { companyId, value }) {
    return this.$axios
      .put(
        `/companies/v1/company/${companyId}/settings/insurance`,
        {
          value,
        },
        {
          baseURL: `${process.env.API_URL}`,
        }
      )
      .then(() => {
        commit('updateInsuranceSetting', {
          companyId,
          value,
        });
      });
  },
  togglePersonalInsurance({ commit }, { companyId, value }) {
    return this.$axios
      .put(
        `/companies/v1/company/${companyId}/settings/personal-insurance`,
        {
          value,
        },
        {
          baseURL: `${process.env.API_URL}`,
        }
      )
      .then(() => {
        commit('updatePersonalInsuranceSetting', {
          companyId,
          value,
        });
      });
  },
  createInitialMilestones(_, { companyId }) {
    console.log('wtf', companyId);

    return this.$axios.post(
      '/companies/v1/bulk-create-initial-milestones',
      { ids: [companyId] },
      {
        baseURL: `${process.env.API_URL}`,
      }
    );
  },
  updateCompanySelfReportedInsurance(
    { commit },
    { companyId, key, carrierName, value = false }
  ) {
    let operation;

    if (value === true) {
      operation = this.$axios.post(
        `/companies/v1/company/${companyId}/self-reported-insurance`,
        {
          key,
          carrierName,
        },
        {
          baseURL: process.env.API_URL,
        }
      );
    } else {
      operation = this.$axios.delete(
        `/companies/v1/company/${companyId}/self-reported-insurance`,
        {
          baseURL: process.env.API_URL,
          params: {
            key,
          },
        }
      );
    }

    return operation.then(() => {
      commit('setCompanyReportedInsurance', {
        companyId,
        key,
        value,
        carrier: carrierName,
      });
    });
  },

  async getOnboardingMilestones({ commit }, { companyId }) {
    const res = await this.$axios.get(`/companies/v2/${companyId}/onboarding`, {
      baseURL: process.env.COMPANY_SERVICE_URL,
    });

    commit('setOnboardingMilestones', {
      companyId,
      milestones: res.data.onboardingMilestones,
    });

    return res;
  },

  async updateOnboardingMilestone(
    { commit },
    { milestone, completed, companyId }
  ) {
    try {
      const res = await this.$axios.put(
        `/companies/v2/${companyId}/onboarding`,
        {
          milestone,
          completed,
        },
        {
          baseURL: process.env.COMPANY_SERVICE_URL,
        }
      );

      commit('setOnboardingMilestone', {
        companyId,
        milestone: res.data,
      });
    } catch (e) {
      // do nothing
    }
  },

  /**
   * Companies, Bulk operations
   */
  async getCompanies(_, { params }) {
    const {
      text,
      status,
      byAdvisor = null,
      isPrimary = null,
      role = null,
      showTest = false,
    } = params;
    const query = qs.stringify({
      text,
      status,
      showTest,
      role,
      byAdvisor,
      isPrimary,
    });

    const { data: companies } = await this.$axios.get(
      `/companies/v1/list?${query}`,
      {
        baseURL: process.env.API_URL,
      }
    );

    return companies;
  },

  reassignCorePlan(
    _,
    { companyId, coupon, planValue, preventChanges, prorate }
  ) {
    return this.$axios.post(
      `/billing/billing/v2/core-plan-reassignment`,
      {
        companyId,
        coupon,
        planValue,
        preventChanges,
        prorate,
      },
      {
        baseURL: `${process.env.PUBLICAPI_URL}`,
      }
    );
  },

  bulkCorePlanReassignment(_, { url }) {
    return this.$axios.post(
      `/billing/billing/v2/bulk-core-plan-reassignment`,
      {
        url,
      },
      {
        baseURL: `${process.env.PUBLICAPI_URL}`,
      }
    );
  },

  bulkUpdateCancellations(_, { url }) {
    return this.$axios.post(
      `/billing/billing/v2/bulk-update-cancellations`,
      {
        url,
      },
      {
        baseURL: `${process.env.PUBLICAPI_URL}`,
      }
    );
  },

  async verifyCompanyHasNectarEnabled(_, { companyId }) {
    return this.$axios
      .get(`/billing/billing/v2/product/nectar-enabled/${companyId}`, {
        baseURL: `${process.env.PUBLICAPI_URL}`,
      })
      .then((response) => response.data?.isNectarEnabled)
      .catch((err) => {
        window.DD_RUM.addError(err);
      });
  },
};

export const mutations = {
  setCompanyInScope(state, companyId) {
    state.companyInScope = companyId;
  },
  // TODO: request through /companies, not /customer or other places
  setCompany(state, { company }) {
    if (company && company._id) {
      Vue.set(state.companies, company._id, company);
    }
  },
  setCompanyPartial(state, { companyId, partial }) {
    if (!(companyId in state.companies)) {
      state.companies[companyId] = {};
    }

    Object.entries(partial).forEach(([key, value]) => {
      Vue.set(state.companies[companyId], key, value);
    });
  },
  setCompanyBasicInfo(state, { companyId, info }) {
    Vue.set(state.companiesBasicInfo, companyId, info);
  },

  setInsuranceInterest(state, { companyId, status }) {
    if (companyId in state.companiesBasicInfo) {
      Vue.set(state.companiesBasicInfo[companyId], 'insuranceInterest', status);
    }
  },
  setCompanyStats(state, { companyId, stats }) {
    Vue.set(state.companiesStats, companyId, stats);
  },
  setCompanyUsers(state, { companyId, users }) {
    Vue.set(state.usersForCompany, companyId, users);
  },
  updateDeliquentStatus(state, { companyId, status }) {
    const company = state.companies[companyId];
    Vue.set(company, 'delinquent', status.delinquent);
    Vue.set(company.stripe.customer, 'delinquent', status.delinquent);
    Vue.set(
      company.stripe,
      'original_payment_date',
      status.original_payment_date
    );
    Vue.set(
      company.stripe,
      'expected_payment_date',
      status.expected_payment_date
    );
    Vue.set(company.stripe, 'attempt_count', status.attempt_count);
  },
  trackCallDisposition(state, { companyId, callId, disposition }) {
    const company = state.companies[companyId];
    const calls = company.calls || [];
    const call = calls.find((c) => c._id === callId && !c.disposition);
    if (call) {
      Vue.set(call, 'disposition', disposition);
    }
  },
  updateGenericSetting(state, { companyId, key, value }) {
    const company = state.companies[companyId];
    Vue.set(company.settings, key, value);
  },
  updateBciSetting(state, { companyId, value }) {
    const company = state.companies[companyId];
    Vue.set(company.settings, 'bci_enabled', value);
    if (!value) {
      Vue.set(company.settings, 'insurance_enabled', false);
    }
  },
  updateInsuranceSetting(state, { companyId, value }) {
    const company = state.companies[companyId];
    Vue.set(company.settings, 'insurance_enabled', value);
  },
  updatePersonalInsuranceSetting(state, { companyId, value }) {
    const company = state.companies[companyId];
    Vue.set(company.settings, 'personal_insurance_enabled', value);
  },
  setCompanyReportedInsurance(state, { companyId, key, value, carrier }) {
    const company = state.companies[companyId];
    Vue.set(company.self_reported_insurance, key, value);
    Vue.set(
      company.self_reported_insurance_carrier,
      key,
      value ? carrier : null
    );
  },

  setOnboardingMilestones(state, { companyId, milestones }) {
    Vue.set(state.onboardingMilestones, companyId, milestones);
  },

  setOnboardingMilestone(state, { companyId, milestone }) {
    const index = state.onboardingMilestones[companyId].findIndex(
      (m) => m.milestone === milestone.milestone
    );

    Vue.set(state.onboardingMilestones[companyId], index, milestone);
  },
};
