<template>
  <v-container>
    <v-layout justify-center>
      <v-card color="error" dark class="pa-3">
        <v-card-title class="justify-center">
          <h1 class="display-2 text-xs-center">
            {{ error.statusCode }}
          </h1>
        </v-card-title>
        <v-card-text class="justify-center">
          <p class="text-xs-center">
            {{ error.message }}
          </p>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            v-if="error.statusCode === 404"
            nuxt
            to="/"
            color="white"
            light
          >
            Home
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: ['error'],
};
</script>
