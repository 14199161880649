import { createUploadLink } from 'apollo-upload-client';
import isomorphicFetch from 'isomorphic-unfetch';

export default function ({ uri, httpOptions }) {
  const {
    headers = { 'keep-alive': 'true' },
    credentials = 'include',
    fetch = isomorphicFetch,
    fetchOptions = {},
    ...otherHttpOptions
  } = httpOptions;

  const httpLink = createUploadLink({
    uri,
    headers,
    credentials,
    fetch,
    fetchOptions,
    ...otherHttpOptions,
  });

  return httpLink;
}
