import CookieNames from '@/constants/CookieNames';
import { setCookie } from '@/lib/cookie-helpers';
import { isV1Token } from '@bambeehr/authentication';
import * as jwt from 'jsonwebtoken';

export default async function ({ route, $axios, app }) {
  let redirectUrl;
  if (route?.query?.authSuccess) {
    // this is the new V3 oauth handler
    // authSuccess is a nonce
    const nonce = route.query.authSuccess;
    // convert it
    try {
      const response = await $axios.post(
        `/auth/v3/openid/bambee-gsuite/convert`,
        {
          nonce,
        },
        {
          baseURL: process.env.PUBLICAPI_URL,
          withCredentials: true,
          headers: {},
        }
      );

      const { accessToken, refreshToken } = response.data;
      redirectUrl = response.data.redirectUrl;
      $axios.setToken(accessToken, 'Bearer');
      app.$axios.setToken(accessToken, 'Bearer');
      setCookie(CookieNames.ACCESS_TOKEN, accessToken);
      setCookie(CookieNames.REFRESH_TOKEN, refreshToken);

      // TODO - we'd rather use the router, but it short-circuits us to /customers instead
      window.location.replace(redirectUrl);
    } catch (e) {
      console.error('converstion error', e);
    }
  } else if (route?.query?.token) {
    // this is the old V1 google oauth handler
    const queryToken = jwt.decode(route.query.token) as any;
    const isV1 = isV1Token(queryToken);
    if (isV1) {
      const response = await $axios.get(
        `${process.env.PUBLICAPI_URL}/auth/v1/upgrade`,
        {
          baseURL: process.env.PUBLICAPI_URL,
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${route.query.token}`,
          },
        }
      );

      const { token, refreshToken } = response.data;
      $axios.setToken(token, 'Bearer');
      app.$axios.setToken(token, 'Bearer');
      setCookie(CookieNames.ACCESS_TOKEN, token);
      setCookie(CookieNames.REFRESH_TOKEN, refreshToken);
    }
  }

  return $axios
    .get('/v0/admin/user/me', {
      baseURL: process.env.PUBLICAPI_URL,
    })
    .then((res) => {
      const user = res.data;
      app.store.commit('setCurrentUser', user);
    })
    .catch((e) => {
      console.log(e);
    });
}
