import Vue from 'vue';

export const state = () => ({
  configurations: [],
});

export const mutations = {
  setConfigurations(state, configurations) {
    state.configurations = configurations;
  },

  setConfiguration(state, configuration) {
    const config_index = state.configurations.findIndex(
      (c) => c._id == configuration._id
    );

    if (config_index != -1) {
      return Vue.set(state.configurations, config_index, configuration);
    }

    state.configurations.push(configuration);
  },

  removeConfigurationByKind(state, kind) {
    const index = state.configurations.findIndex((c) => c.kind == kind);

    state.configurations.splice(index, 1);
  },
};

export const getters = {
  allConfig(state) {
    return state.configurations;
  },

  System(state) {
    return state.configurations.find((c) => c.name == 'System');
  },

  DefaultOnboarding(state) {
    return state.configurations.find((c) => c.name == 'DefaultOnboarding');
  },

  DefaultChatOnly(state) {
    return state.configurations.find((c) => c.name == 'DefaultChatOnly');
  },

  DefaultSales(state) {
    return state.configurations.find((c) => c.name == 'DefaultSales');
  },

  DefaultInsuranceAgents(state) {
    return state.configurations.find((c) => c.name == 'DefaultInsuranceAgents');
  },

  NewHireNotice(state) {
    return state.configurations.find((c) => c.name == 'NewHireNotice');
  },

  DefaultTodos(state) {
    return state.configurations.find((c) => c.name == 'DefaultTodos');
  },

  DefaultDuplicateCalculation(state) {
    return state.configurations.find(
      (c) => c.name == 'DefaultDuplicateCalculation'
    );
  },

  PandaDoc(state) {
    return state.configurations.find((c) => c.name == 'PandaDoc');
  },

  Calendly(state) {
    return state.configurations.find((c) => c.name === 'Calendly');
  },

  DefaultRequestTime(state) {
    return state.configurations.find((c) => c.name == 'DefaultRequestTime');
  },

  Vonage(state) {
    return state.configurations.find((c) => c.name == 'Vonage');
  },

  NowCerts(state) {
    return state.configurations.find((c) => c.name == 'NowCerts');
  },

  CancellationReasons(state) {
    const data = state.configurations.find(
      (c) => c.name == 'CancellationReasons'
    );

    return (data && data.groups) || [];
  },
};

export const actions = {
  getAll({ commit }) {
    return this.$axios.get('/configuration/all').then((res) => {
      const configurations = res.data;
      commit('setConfigurations', configurations);

      return configurations;
    });
  },
  create({ commit }, { kind, data }) {
    return this.$axios
      .post(`/configuration/create/${kind}`, { data })
      .then((res) => {
        const config = res.data;
        commit('setConfiguration', config);

        return config;
      });
  },
  remove({ commit }, kind) {
    return this.$axios.post(`/configuration/remove/${kind}`).then((res) => {
      commit('removeConfigurationByKind', kind);
    });
  },
  update({ commit }, { kind, data }) {
    return this.$axios
      .post(`/configuration/update/${kind}`, data)
      .then((res) => {
        const config = res.data;

        commit('setConfiguration', config);

        return config;
      });
  },
};
