export default ({ store, redirect, route }) => {
  if (!store.state.current_user) {
    return;
  }

  if (store.state.current_user.role !== 'insurance') {
    return;
  }

  const userAccess = ['customers', 'leads', 'settings'];

  const name = route.path.split('/')[1];
  const hasAccess = userAccess.includes(name);

  if (!hasAccess) {
    return redirect('/leads/insurance');
  }
};
