export const state = () => ({
  reportCards: [],
  current: {},
  companyReportCards: [],
});

export const mutations = {
  setAll(state, reportCards) {
    state.reportCards = reportCards;
  },

  setCurrent(state, reportCard) {
    Vue.set(state, 'current', reportCard);
  },

  setCompanyReportCards(state, reportCards) {
    state.companyReportCards = reportCards;
  },
};

export const getters = {
  getAll(state) {
    return state.reportCards;
  },
  getCurrent(state) {
    return state.current;
  },
  getCompanyReportCards(state) {
    return state.companyReportCards;
  },
};

export const actions = {
  create({ commit }, { company_id, employees }) {
    return this.$axios
      .post('/report-card/create', { company_id,
employees })
      .then((res) => {
        commit('setCurrent', res.data);

        return res.data;
      });
  },

  getAll({ commit }) {
    return this.$axios.get('/report-card/getAll').then((res) => {
      commit('setAll', res.data);

      return res.data;
    });
  },

  getByCompanyId({ commit }, { company_id }) {
    return this.$axios.get(`/report-card/getBy/${company_id}`).then((res) => {
      commit('setCompanyReportCards', res.data);

      return res.data;
    });
  },

  getById({ commit }, { reportCard_id }) {
    return this.$axios.get(`/report-card/byId/${reportCard_id}`).then((res) => {
      commit('setCurrent', res.data);

      return res.data;
    });
  },

  saveReportCard({ commit }, { reportCard_id, payload }) {
    return this.$axios
      .post(`/report-card/byId/${reportCard_id}/save`, { payload })
      .then((res) => {
        commit('setCurrent', res.data);

        return res.data;
      });
  },

  completeReportCard({ commit }, { reportCard_id }) {
    return this.$axios
      .post(`/report-card/byId/${reportCard_id}/complete`)
      .then((res) => {
        commit('setCurrent', res.data);

        return res.data;
      });
  },
};
