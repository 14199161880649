import has from 'lodash/has';
import pick from 'lodash/pick';
import Vue from 'vue';

export const state = () => ({
  terminations: {},
  fullTerminations: {},
  customerMap: {}, // customerId: terminationId[]
});

export const getters = {
  getById: (state) => (terminationId) => state.terminations[terminationId],
  getByCustomerId: (state) => (customerId) => {
    return Object.values(
      pick(state.terminations, state.customerMap[customerId])
    );
  },
};

export const actions = {
  fetchById({ state, dispatch }, terminationId) {
    if (
      'terminationId' in state.fullTerminations &&
      'terminationId' in state.terminations
    ) {
      return Promise.resolve();
    }

    return dispatch('getById', terminationId);
  },
  getById({ commit }, terminationId) {
    return this.$axios.get(`/termination/${terminationId}`).then((res) => {
      commit('saveFullTermination', res.data);
    });
  },
  fetchForCustomer({ dispatch }, customerId) {
    // todo: request once in a while only
    return dispatch('getForCustomer', customerId);
  },
  getForCustomer({ commit }, customerId) {
    return this.$axios
      .get(`/customer/${customerId}/terminations`)
      .then((res) => {
        commit('setForCustomer', {
          customerId,
          terminationIds: res.data.map((t) => t._id),
        });
        commit('bulkSaveTerminations', res.data);
      })
      .catch(() => {
        commit('setForCustomer', {
          customerId,
          terminationIds: [],
        });
      });
  },
  confirm({ commit }, { terminationId, customerId }) {
    return this.$axios
      .post(`/customer/${customerId}/confirm-termination`, { terminationId })
      .then((res) => {
        commit('saveTermination', res.data);
      });
  },
  cancel({ commit }, { terminationId, customerId }) {
    // todo: convert to DELETE /termination/:terminationId (or PUT .../cancel)
    return this.$axios
      .post(`/customer/${customerId}/cancel-termination`, {
        termination_id: terminationId,
      })
      .then((res) => {
        commit('deleteTermination', terminationId);

        return res;
      });
  },
  async delete({ commit }, { terminationId }) {
    const res = await this.$axios.delete(`/termination/${terminationId}`);
    commit('deleteTermination', terminationId);

    return res;
  },
  save({ commit }, { terminationId, terminationData }) {
    this.$axios
      .put(`/termination/${terminationId}`, terminationData)
      .then((res) => {
        commit('saveFullTermination', res.data);
      });
  },
  send({ commit }, { terminationId, customerId }) {
    return this.$axios
      .post(`/customer/${customerId}/send-termination`, { terminationId })
      .then((res) => {
        commit('saveTermination', res.data);
      });
  },
  retract({ commit }, { terminationId }) {
    return this.$axios
      .put(`/termination/${terminationId}/retract`, { terminationId })
      .then((res) => {
        commit('saveTerminationPartial', {
          terminationId,
          partial: res.data,
        });
      });
  },
  addDocuments({ commit }, { terminationId, templateIds }) {
    return this.$axios
      .post(`/termination/${terminationId}/documents`, {
        templateIds,
      })
      .then((res) => {
        commit('addTerminationDocuments', {
          terminationId,
          docs: res.data,
        });
      });
  },
  addUpload({ commit }, { terminationId, files }) {
    return this.$axios
      .post(`/termination/${terminationId}/uploads`, {
        files,
      })
      .then((res) => {
        commit('addTerminationUpload', {
          terminationId,
          upload: res.data,
        });
      });
  },
  deleteAttachment({ commit }, { type, terminationId, documentId }) {
    return this.$axios
      .delete(`/termination/${terminationId}/${type}/${documentId}`)
      .then(
        commit('deleteTerminationAttachment', {
          type,
          terminationId,
          documentId,
        })
      );
  },
};

export const mutations = {
  setForCustomer(state, { customerId, terminationIds }) {
    Vue.set(state.customerMap, customerId, terminationIds);
  },
  saveTermination(state, termination) {
    Vue.set(state.terminations, termination._id, {
      ...state.terminations[termination._id],
      ...termination,
    });
  },
  saveFullTermination(state, termination) {
    // todo: decouple and move to employee
    if (has(state.terminations, `${termination._id}.employee`)) {
      termination.employee = state.terminations[termination._id].employee;
    }

    Vue.set(state.terminations, termination._id, termination);
    Vue.set(state.fullTerminations, termination._id, new Date().getTime());
  },
  saveTerminationPartial(state, { terminationId, partial }) {
    if (!('terminationId' in state.terminations)) {
      state.terminations[terminationId] = {};
    }

    Object.entries(partial).forEach(([key, value]) => {
      Vue.set(state.terminations[terminationId], key, value);
    });
  },
  deleteTermination(state, terminationId) {
    Vue.delete(state.terminations, terminationId);
  },
  bulkSaveTerminations(state, terminations) {
    terminations.forEach((t) => {
      Vue.set(state.terminations, t._id, {
        ...state.terminations[t._id],
        ...t,
      });
    });
  },
  addTerminationDocuments(state, { terminationId, docs }) {
    if ('terminationId' in state.terminations) {
      state.terminations[
        terminationId
      ].states.termination_bambee_documents.push(...docs);
    }
  },
  addTerminationUpload(state, { terminationId, upload }) {
    if ('terminationId' in state.terminations) {
      state.terminations[terminationId].states.termination_packet.push(upload);
    }
  },
  deleteTerminationAttachment(state, { terminationId, documentId, type }) {
    const arr =
      type === 'documents'
        ? 'termination_bambee_documents'
        : 'termination_packet';
    if (has(state.terminations, `${terminationId}.states.${arr}`)) {
      const docs = state.terminations[terminationId].states[arr];
      const index = docs.findIndex((d) => d._id === documentId);

      if (index >= 0) {
        Vue.delete(docs, index);
      }
    }
  },
  // todo: decouple termination and documents
  updateTerminationDocument(state, { terminationId, document }) {
    if (
      has(
        state.terminations,
        `${terminationId}.states.termination_bambee_documents`
      )
    ) {
      const docs =
        state.terminations[terminationId].states.termination_bambee_documents;
      const index = docs.findIndex((d) => d._id === document.id);

      if (index >= 0) {
        Vue.set(docs[index], 'name', document.name);
      }
    }
  },
};
