import teamRoleNames from '@/lib/globals/roles';
import Vue from 'vue';

function emptyUser() {
  return {
    id: null,
    avatarUrl: '',
    name: '',
    yearsExperience: '',
  };
}

function getEmptyTree() {
  return [
    {
      id: null,
      isPrimary: null,
      role: 'hrm',
      user: emptyUser(),
    },
    {
      id: null,
      isPrimary: null,
      role: 'ae',
      user: emptyUser(),
    },
    {
      id: null,
      isPrimary: null,
      role: 'obs',
      user: emptyUser(),
    },
    {
      id: null,
      isPrimary: null,
      role: 'oc',
      user: emptyUser(),
    },
    {
      id: null,
      isPrimary: null,
      role: 'ac',
      user: emptyUser(),
    },
    {
      id: null,
      isPrimary: null,
      role: 'hbs',
      user: emptyUser(),
    },
    {
      id: null,
      isPrimary: null,
      role: 'hrc',
      user: emptyUser(),
    },
    {
      id: null,
      isPrimary: null,
      role: 'bm',
      user: emptyUser(),
    },
    {
      id: null,
      isPrimary: null,
      role: 'pym',
      user: emptyUser(),
    },
  ];
}

export const state = () => ({
  team: [],
  teamsByCompanyId: {},
});

export const mutations = {
  setTeam(state, team) {
    let defaultData = getEmptyTree();
    defaultData = defaultData.map((data) => {
      if (team.find((t) => t.role === data.role)) {
        const role = team.find((t) => t.role === data.role);

        if (!role.user) {
          role.user = emptyUser();
        }

        return role;
      }

      return data;
    });
    state.team = defaultData;
  },
  setTeamByCompanyId(state, { companyId, team }) {
    Vue.set(state.teamsByCompanyId, companyId, team);
  },
};

export const getters = {
  teamForCompany: (state) => (companyId) => state.teamsByCompanyId[companyId],

  // For Company In Scope
  forCompanyInScope: (state, getters, rootState) =>
    getters.teamForCompany(rootState.companies.companyInScope) || [],
};

export const actions = {
  fetchCompanyAdvisors({ dispatch, state }, { companyId }) {
    if (state.teamsByCompanyId.hasOwnProperty(companyId)) {
      return Promise.resolve();
    }

    return dispatch('getCompanyAdvisors', companyId);
  },

  // Used to get all role assignments for Company
  getCompanyAdvisors({ commit, rootState }, companyId) {
    const realCompanyId = companyId || rootState.companies.companyInScope;

    return this.$axios
      .get(`/companies/v1/company/${realCompanyId}/advisors`, {
        baseURL: `${process.env.API_URL}`,
      })
      .then((res) => {
        res.data.forEach((u) => {
          u.roleName = teamRoleNames[u.role] || '';
        });
        commit('setTeam', res.data);
        commit('setTeamByCompanyId', {
          companyId: realCompanyId,
          team: res.data.filter((u) => u.id),
        });
      });
  },
  // END

  // Used to Set and Change Assignment
  setCompanyAdvisorByRole({ commit }, { company_id, role, userId }) {
    return this.$axios
      .put(
        `/companies/v1/company/${company_id}/advisors/by-role/${role}`,
        { userId },
        { baseURL: `${process.env.API_URL}` }
      )
      .then((res) => {
        return res;
      });
  },
  // END

  // Used to Remove Assignment
  deleteCompanyAdvisorByAdvisorId({ commit }, { company_id, advisor_id }) {
    return this.$axios
      .delete(`/companies/v1/advisors/advisor/${advisor_id}`, {
        baseURL: `${process.env.API_URL}`,
      })
      .then((res) => {
        return res;
      });
  },
  // END

  // Used to set who is Primary Contact
  setAdvisorAsPrimary({ commit }, { company_id, role, userId }) {
    return this.$axios
      .post(
        `/companies/v1/company/${company_id}/advisors/by-role/${role}/set-primary`,
        { userId },
        { baseURL: `${process.env.API_URL}` }
      )
      .then((res) => {
        return res;
      });
  },
  // END
};
