import Vue from 'vue';
import dayjs from 'dayjs';
import orderBy from 'lodash/orderBy';

const bciDefaults = {
  thresholds: {
    low: 600,
    high: 900,
  },
  minScore: 200,
  maxScore: 1100,
  chartExtension: 120,
};

export const state = () => ({
  bciDefaults,
  scores: {},
  policyCompletion: {},
  recordCompletion: {},
  reportCardCompletion: {},
  actionItems: {},
  publishedItemsByCompany: {},
  unpublishedItemsByCompany: {},
});

export const getters = {
  scores: (state, getters, rootState, rootGetters) =>
    state.scores[rootGetters.companyId] || [],
  scoresLoaded: (state, getters, rootState, rootGetters) =>
    Array.isArray(state.scores[rootGetters.companyId]),
  policyCompletion: (state, getters, rootState, rootGetters) =>
    state.policyCompletion[rootGetters.companyId],
  recordCompletion: (state, getters, rootState, rootGetters) =>
    state.recordCompletion[rootGetters.companyId],
  reportCardCompletion: (state, getters, rootState, rootGetters) =>
    state.reportCardCompletion[rootGetters.companyId],

  // Action Items
  publishedActionItems: (state, getters, rootState, rootGetters) => {
    const publishedCompanyItems = (
      state.publishedItemsByCompany[rootGetters.companyId] || []
    ).map((id) => state.actionItems[id]);

    /**
     * Sorting order
     *
     * Unresolved items sorted by creation date, desc
     * Resolved items sorted by resolve date, desc
     */
    return orderBy(
      publishedCompanyItems,
      ['resolvedAt', 'createdAt'],
      ['desc', 'desc']
    );
  },
  unpublishedActionItems: (state, getters, rootState, rootGetters) => {
    const unpublishedCompanyItems = (
      state.unpublishedItemsByCompany[rootGetters.companyId] || []
    ).map((id) => state.actionItems[id]);

    const sortingOrder = ['high', 'normal', 'none'];

    return unpublishedCompanyItems.sort((a, b) => {
      return sortingOrder.indexOf(a.impact) - sortingOrder.indexOf(b.impact);
    });
  },
  actionItemById: (state) => (id) => state.actionItems[id],
};

export const actions = {
  fetchReportCards({ dispatch, state, rootGetters: { companyId } }) {
    if (state.reportCardCompletion.hasOwnProperty(companyId)) {
      return Promise.resolve();
    }

    return dispatch('getReportCardCompletionInfo', companyId);
  },

  confirmNextStep({ commit, rootGetters: { companyId } }, actionItemId) {
    return this.$axios
      .patch(
        `/companies/v1/company/${companyId}/action-items/${actionItemId}/confirm-next-step`,
        {},
        { baseURL: `${process.env.API_URL}` }
      )
      .then((res) => {
        const payload = {
          id: actionItemId,
          nextStepTakenAt: res.data.nextStepTakenAt,
        };
        commit('setNextStepTakenAt', payload);
      });
  },

  // Action Items Bulk Actions
  getActionItems({ commit, rootGetters: { companyId } }) {
    return this.$axios
      .get(`/companies/v1/company/${companyId}/action-items?unpublished=true`, {
        baseURL: `${process.env.API_URL}`,
      })
      .then((res) => {
        commit('setActionItems', {
          companyId,
          actionItems: res.data,
        });
      });
  },

  // Action Items CRUD
  createActionItem({ commit, rootGetters: { companyId } }, actionItem) {
    return this.$axios
      .post(
        `/companies/v1/company/${companyId}/action-items/action-item`,
        actionItem,
        { baseURL: `${process.env.API_URL}` }
      )
      .then((res) => {
        commit('addActionItem', {
          companyId,
          actionItem: res.data,
        });
      });
  },
  editActionItem({ commit, rootGetters: { companyId } }, actionItem) {
    return this.$axios
      .patch(
        `/companies/v1/company/${companyId}/action-items/${actionItem.id}`,
        actionItem,
        { baseURL: `${process.env.API_URL}` }
      )
      .then((res) => {
        commit('updateActionItem', res.data);
      });
  },
  deleteActionItem({ commit, rootGetters: { companyId } }, actionItemId) {
    return this.$axios
      .delete(
        `/companies/v1/company/${companyId}/action-items/${actionItemId}`,
        { baseURL: `${process.env.API_URL}` }
      )
      .then(() => {
        commit('deleteItem', { companyId, actionItemId });
      });
  },

  // Action Item Additional Actions
  resolveActionItem({ commit, rootGetters: { companyId } }, actionItemId) {
    return this.$axios
      .patch(
        `/companies/v1/company/${companyId}/action-items/${actionItemId}/resolve`,
        {},
        { baseURL: `${process.env.API_URL}` }
      )
      .then((res) => {
        const payload = {
          id: actionItemId,
          resolvedAt: res.data.resolvedAt,
        };
        commit('setResolvedAt', payload);
      });
  },
  publishActionItem({ commit, rootGetters: { companyId } }, actionItemId) {
    return this.$axios
      .patch(
        `/companies/v1/company/${companyId}/action-items/${actionItemId}/publish`,
        {},
        { baseURL: `${process.env.API_URL}` }
      )
      .then((res) => {
        const payload = {
          id: actionItemId,
          publishedAt: res.data.publishedAt,
          companyId,
        };
        commit('setPublishedAt', payload);
      });
  },

  // Company Scores
  fetchCompanyScores({ dispatch, state, rootGetters: { companyId } }) {
    if (typeof state.scores[companyId] === 'undefined') {
      return dispatch('getCompanyScores');
    }

    return Promise.resolve();
  },
  getCompanyScores({ commit, rootGetters: { companyId } }) {
    return this.$axios
      .get(`/companies/v1/company/${companyId}/bci/scores?limit=26`, {
        baseURL: `${process.env.API_URL}`,
      })
      .then((res) => {
        commit('setCompanyScores', {
          companyId,
          scores: res.data.scores,
        });
      });
  },
  createCompanyScore({ commit }, { companyId }) {
    return this.$axios
      .post(`/companies/v1/company/${companyId}/bci/scores`, null, {
        baseURL: `${process.env.API_URL}`,
      })
      .then((res) => {
        commit('addCompanyScore', {
          companyId,
          score: res.data,
        });
      });
  },
  overrideCompanyScore({ commit }, { companyId }) {
    return this.$axios
      .patch(`/companies/v1/company/${companyId}/bci/scores/last-score`, null, {
        baseURL: `${process.env.API_URL}`,
      })
      .then((res) => {
        commit('replaceCompanyScore', {
          companyId,
          score: res.data,
        });
      });
  },

  getPoliciesCompletionInfo({ commit, rootGetters: { companyId } }) {
    return this.$axios
      .get(`/companies/v1/company/${companyId}/bci/unsigned-documents`, {
        baseURL: `${process.env.API_URL}`,
      })
      .then((res) => {
        commit('setState', {
          companyId,
          percent: res.data.completionPercent,
          type: 'policyCompletion',
        });
      });
  },

  getEmployeeRecordCompletionInfo({ commit, rootGetters: { companyId } }) {
    return this.$axios
      .get(`/companies/v1/company/${companyId}/bci/record-keeping`, {
        baseURL: `${process.env.API_URL}`,
      })
      .then((res) => {
        commit('setState', {
          companyId,
          percent: res.data.completionPercent,
          type: 'recordCompletion',
        });
      });
  },

  getReportCardCompletionInfo({ commit, rootGetters: { companyId } }) {
    return this.$axios
      .get(`/companies/v1/company/${companyId}/bci/report-card`, {
        baseURL: `${process.env.API_URL}`,
      })
      .then((res) => {
        commit('setState', {
          companyId,
          percent: res.data.completionPercent,
          type: 'reportCardCompletion',
        });
      });
  },
};

export const mutations = {
  // Action Items Bulk Actions
  setActionItems(state, { companyId, actionItems }) {
    const publishedIds = [];
    const unpublishedIds = [];
    actionItems.forEach((actionItem) => {
      Vue.set(state.actionItems, actionItem.id, actionItem);

      if (actionItem.publishedAt) {
        publishedIds.push(actionItem.id);
      } else {
        unpublishedIds.push(actionItem.id);
      }
    });

    Vue.set(state.publishedItemsByCompany, companyId, publishedIds);
    Vue.set(state.unpublishedItemsByCompany, companyId, unpublishedIds);
  },

  // Action Item CRUD
  addActionItem(state, { companyId, actionItem }) {
    Vue.set(state.actionItems, actionItem.id, actionItem);
    state.publishedItemsByCompany[companyId] =
      state.publishedItemsByCompany[companyId] || [];
    state.publishedItemsByCompany[companyId].push(actionItem.id);
  },
  updateActionItem(state, actionItem) {
    Vue.set(state.actionItems, actionItem.id, actionItem);
  },
  deleteItem(state, { companyId, actionItemId }) {
    const pool = state.actionItems[actionItemId].publishedAt
      ? 'publishedItemsByCompany'
      : 'unpublishedItemsByCompany';
    Vue.delete(state.actionItems, actionItemId);
    const index = state[pool][companyId].indexOf(actionItemId);
    Vue.delete(state[pool][companyId], index);
  },

  // Action Item Additional Actions
  setNextStepTakenAt(state, { id, nextStepTakenAt }) {
    Vue.set(state.actionItems[id], 'nextStepTakenAt', nextStepTakenAt);
  },
  setResolvedAt(state, { id, resolvedAt }) {
    Vue.set(state.actionItems[id], 'resolvedAt', resolvedAt);
  },
  setPublishedAt(state, { id, publishedAt, companyId }) {
    Vue.set(state.actionItems[id], 'publishedAt', publishedAt);

    const index = state.unpublishedItemsByCompany[companyId].indexOf(id);
    Vue.delete(state.unpublishedItemsByCompany[companyId], index);
    state.publishedItemsByCompany[companyId].push(id);
  },

  // Company Scores
  setCompanyScores(state, { companyId, scores }) {
    Vue.set(state.scores, companyId, scores);
  },
  addCompanyScore(state, { companyId, score }) {
    if (Array.isArray(state.scores[companyId])) {
      state.scores[companyId].unshift(score);
    }
  },
  replaceCompanyScore(state, { companyId, score }) {
    if (
      Array.isArray(state.scores[companyId]) &&
      state.scores[companyId].length
    ) {
      state.scores[companyId].splice(0, 1, score);
    }
  },

  setState(state, { companyId, percent, type }) {
    Vue.set(state[type], companyId, percent);
  },
};
