import CookieNames from '@/constants/CookieNames';
import { getCookie, setCookie } from '@/lib/cookie-helpers';
import jwtDecode from 'jwt-decode';

let isRefreshing = false;
let refreshQueue: Array<any> = [];

export function jwtExpired(token, buffer = 0) {
  const decoded = jwtDecode(token) as any;

  return decoded.exp * 1000 - buffer < Date.now();
}

export async function getRefreshedToken(axios) {
  const existingAccessToken = getCookie(CookieNames.ACCESS_TOKEN);
  const existingRefreshToken = getCookie(CookieNames.REFRESH_TOKEN);
  const response = await axios.post(
    `/auth/v3/refresh`,
    { refreshToken: existingRefreshToken },
    {
      baseURL: process.env.PUBLICAPI_URL,
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${existingAccessToken}`,
      },
    }
  );

  const { accessToken, refreshToken } = response.data;

  axios.setToken(accessToken, 'Bearer');
  setCookie(CookieNames.ACCESS_TOKEN, accessToken);
  setCookie(CookieNames.REFRESH_TOKEN, refreshToken);

  return accessToken;
}

export async function handleToken(axios, route, inject, store, redirect) {
  // eslint-disable-next-line camelcase
  const { redir, redirect_uri } = route.query;

  // eslint-disable-next-line camelcase
  const queryRedirect = redirect_uri ? decodeURI(redirect_uri) : redir;

  const { path } = route;

  const accessToken = getCookie(CookieNames.ACCESS_TOKEN);
  const refreshToken = getCookie(CookieNames.REFRESH_TOKEN);

  if (!accessToken && !refreshToken) {
    return false;
  }

  axios.setToken(accessToken, 'Bearer');

  return redirect(queryRedirect || path || '/');
}

export default async function setupAxios(
  { $axios, route, redirect, store },
  inject
) {
  // This is a known "WONTFIX" but in nuxt, believe it or not.
  // https://github.com/nuxt-community/axios-module/issues/168
  $axios.onRequest((config) => {
    config.withCredentials = true;

    return config;
  });

  /**
   * TODO: when the refresh process is in progress, other requests need to be stalled
   * until the refresh is completed.
   */
  $axios.interceptors.request.use(async (config) => {
    if (config._isInterceptor) {
      return config;
    }
    if (isRefreshing) {
      return new Promise<string>((resolve, reject) => {
        refreshQueue.push({
          resolve,
          reject,
        });
      }).then((newToken) => {
        config.headers.Authorization = `Bearer ${newToken}`;

        return config;
      });
    }

    const existingAccessToken = getCookie(CookieNames.ACCESS_TOKEN);
    const existingRefreshToken = getCookie(CookieNames.REFRESH_TOKEN);
    if (existingAccessToken && existingRefreshToken) {
      const { exp } = jwtDecode(existingAccessToken) as any;
      if (exp * 1000 - Date.now() < 150) {
        // refresh - this sentinel puts events into the stalled queue
        isRefreshing = true;
        const response = await $axios.post(
          `/auth/v3/refresh`,
          { refreshToken: existingRefreshToken },
          {
            baseURL: process.env.PUBLICAPI_URL,
            headers: {
              Authorization: `Bearer ${existingAccessToken}`,
            },
            _isInterceptor: true,
            withCredentials: process.env.env !== 'dev',
            validateStatus(status) {
              return true;
            },
          }
        );
        if (response.status !== 201) {
          await Promise.all(refreshQueue.map((i) => i.reject(response)));
          refreshQueue = [];
          throw new Error('You have been logged out');
        }

        const { accessToken, refreshToken } = response.data;

        $axios.setToken(accessToken, 'Bearer');
        setCookie(CookieNames.ACCESS_TOKEN, accessToken);
        setCookie(CookieNames.REFRESH_TOKEN, refreshToken);
        isRefreshing = false;
        await Promise.all(refreshQueue.map((i) => i.resolve(accessToken)));
        refreshQueue = [];
      }
    }

    return config;
  });

  await handleToken($axios, route, inject, store, redirect);
}
